import React from "react";
import { Button } from "react-bootstrap";

const PopupModal = ({ popupId, onPopup, popupText, type, onCancel }) => {
  return (
    <div className="modal confirmation-popup" id={popupId}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body">
            <h2 className="text-center">Are you sure ?</h2>
            <p className="text-center">{popupText}</p>

            <div className="modal-footer mt-3">
              <Button
                type="button"
                variant="add-data out-line-btn mr-2 btn btn-danger"
                data-dismiss="modal"
                onClick={() => (onCancel ? onCancel() : null)}
              >
                Cancel
              </Button>
              <Button
                type="button"
                // variant="add-data"
                variant={`add-data m-0  ${
                  type === 1 || type === 3
                    ? "active-status-bag-- btn btn-success"
                    : type === 12
                    ? ""
                    : "rejected-status-bag-- btn btn-success"
                }`}
                data-dismiss="modal"
                onClick={(e) => {
                  e.preventDefault();
                  onPopup();
                }}
              >
                {/* <img src="/assets/images/save-btn.svg" alt="img" />{" "} */}
                {(type === 1 || type === 3) && "Yes, Delete"}
                {(type === 2 || type === 4) && "Yes, Inactivate"}
                {(!type || type === 5 || type === 6) && "Delete"}
                {(type === 7 || type === 9) && "Reject"}
                {(type === 10 || type === 11) && "Approve"}
                {type === 8 && "Send"}
                {type === 12 && "Yes, Add"}
                {type === 13 && "Yes, Reset"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PopupModal;
