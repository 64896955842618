import React,{Component, Fragment} from "react";
import { ToastContainer } from 'react-toastify';
import Layout from "./components/Layout";
import 'react-toastify/dist/ReactToastify.css';
//import "primeicons/primeicons.css";

import 'bootstrap/dist/css/bootstrap.min.css';


class App extends Component{

  render(){
      return (
        <Fragment>
          <Layout />
          <ToastContainer
            position="top-right"
            autoClose={4000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
          />
        </Fragment>
      );
    
  }
}


export default App;
