import React, { Component } from "react";
// import Header from "./include/header";
// import Sidebar from "./include/sidebar";
// import Footer from "./include/footer";
class Dashboard extends Component {
    constructor() {
        super();
    }
    render() {
        return (
            <React.Fragment>
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h4 mb-0 text-gray-800">Dashboard</h1>
                    <a href="#" className="d-none d-sm-none  btn btn-sm btn-primary shadow-sm d-none"><i
                        className="fas fa-download fa-sm text-white-50"></i> Generate Report</a>
                </div>
                <div className="row dashboardContent">
                    <div className="col-xl-3 col-md-6 mb-4">
                        <a href="data-providers-list.php">
                            <div className="card border-left-success shadow h-100 py-2">
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-sm font-weight-bold text-success mb-1">Primary Data
                                            </div>
                                            <div className="h5 mb-0 font-weight-bold text-gray-800">12</div>
                                        </div>
                                        <div className="col-auto">
                                            <i className="fal fa-database fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col-xl-3 col-md-6 mb-4">
                        <a href="curator-taxonomist-list.php">
                            <div className="card border-left-info shadow h-100 py-2">
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-sm font-weight-bold text-info mb-1">Secondary Data</div>
                                            <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">50</div>
                                        </div>
                                        <div className="col-auto">
                                            <i className="fal fa-database fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                <div className="dashboardContent">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex justify-content-between align-items-center">
                            <h6 className="m-0  font-weight-bold text-primary d-flex align-items-center"> <i
                                className="fab  fa-pagelines fa-2x text-gray-300"></i> <span className="ml-2"> Total Species 50,000
                                </span></h6>
                            <a href="" className="btn btn-sm btn-primary"><i className="fal  fa-eye"></i> View Species List</a>
                        </div>
                        <div className="card-body p-4">

                            <div className="row">
                                <div className="col-xl-3 col-md-6 mb-4">
                                    <div className="card border-left-primary shadow h-100 py-2 ">
                                        <div className="card-body">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col mr-2">
                                                    <div className="text-sm font-weight-bold text-primary mb-1">Plants</div>
                                                    <div className="h5 mb-0 font-weight-bold text-gray-800">20,000</div>
                                                </div>
                                                <div className="col-auto">
                                                    <i className="fab  fa-pagelines fa-2x text-gray-300"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-md-6 mb-4">
                                    <div className="card border-left-primary shadow h-100 py-2">
                                        <div className="card-body">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col mr-2">
                                                    <div className="text-sm font-weight-bold text-primary mb-1">Animals</div>
                                                    <div className="h5 mb-0 font-weight-bold text-gray-800">20,000</div>
                                                </div>
                                                <div className="col-auto">
                                                    <i className="fas  fa-monkey fa-2x text-gray-300"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-md-6 mb-4">
                                    <div className="card border-left-primary shadow h-100 py-2">
                                        <div className="card-body">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col mr-2">
                                                    <div className="text-sm font-weight-bold text-primary mb-1">Fungi</div>
                                                    <div className="h5 mb-0 font-weight-bold text-gray-800">20,000</div>
                                                </div>
                                                <div className="col-auto">
                                                    <i className="fab  fa-pagelines fa-2x text-gray-300"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-md-6 mb-4 ">
                                    <div className="card border-left-primary shadow h-100 py-2">
                                        <div className="card-body">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col mr-2">
                                                    <div className="text-sm font-weight-bold text-primary mb-1">Protozoa</div>
                                                    <div className="h5 mb-0 font-weight-bold text-gray-800">20,000</div>
                                                </div>
                                                <div className="col-auto">
                                                    <i className="fab  fa-pagelines fa-2x text-gray-300"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-md-6 mb-4 mb-md-0">
                                    <div className="card border-left-primary shadow h-100 py-2">
                                        <div className="card-body">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col mr-2">
                                                    <div className="text-sm font-weight-bold text-primary mb-1">Bacteria</div>
                                                    <div className="h5 mb-0 font-weight-bold text-gray-800">20,000</div>
                                                </div>
                                                <div className="col-auto">
                                                    <i className="fas  fa-bacteria fa-2x text-gray-300"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-md-6 mb-4 mb-md-0">
                                    <div className="card border-left-primary shadow h-100 py-2">
                                        <div className="card-body">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col mr-2">
                                                    <div className="text-sm font-weight-bold text-primary mb-1">Archaea</div>
                                                    <div className="h5 mb-0 font-weight-bold text-gray-800">20,000</div>
                                                </div>
                                                <div className="col-auto">
                                                    <i className="fas  fa-turtle fa-2x text-gray-300"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-md-6">
                                    <div className="card border-left-primary shadow h-100 py-2">
                                        <div className="card-body">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col mr-2">
                                                    <div className="text-sm font-weight-bold text-primary mb-1">Chromista</div>
                                                    <div className="h5 mb-0 font-weight-bold text-gray-800">20,000</div>
                                                </div>
                                                <div className="col-auto">
                                                    <i className="fab  fa-pagelines fa-2x text-gray-300"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </React.Fragment >
        )

    }
}
export default Dashboard;