import { combineReducers } from "redux";
import { UserReducer } from "./user";
 import {AgentsReducer} from "./realEstateAgents";
// import {CuratorReducer} from "./Curator";
// import {ChangePasswordReducer} from "./ChangePasswordReducer";
// import {ResetPasswordReducer} from "./ResetPasswordReducer";
// import {CountryReducer} from "./CountryReducer";
// import {BioReducer} from "./BioGraphicReducer";
// import {RoleReducer} from "./RoleReducer";
// import {StateReducer} from "./StateReducer";
// import {MasterReducer} from "./MasterReducer";
// import {DataProviderReducer} from "./DataProviderReducer";
// import {CategoryReducer} from "./CategoryReducer";
// import {ListUtilityReducer} from "./ListUtiltiyReducer"
export default combineReducers({
  UserReducer,
  AgentsReducer,
//   CuratorReducer,
//   ChangePasswordReducer,
//   ResetPasswordReducer,
//   CountryReducer,
//   BioReducer,
//   RoleReducer,
//   StateReducer,
//   MasterReducer,
//   DataProviderReducer,
//   CategoryReducer,
//   ListUtilityReducer
});
