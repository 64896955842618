import { PENDING, ERROR, LOGIN_SUCCESS, LOGOUT_SUCCESS } from "./types/index";
import { toast } from "react-toastify";
import { userLogin, userLogout} from "../data/user";
//import {ExceptionHandler} from "../Utility/ExceptionHandler";

export function UserLogin(params) {
    return async (dispatch) => {
        dispatch({
            type: PENDING,
        });
        try {
            const loginData = await userLogin(params).then((res) => res);
            dispatch({
                type: LOGIN_SUCCESS,
                payload: loginData.data
            });
        } catch (err) {
            dispatch({
                type: ERROR,
                error: err.response
            });
        }
    };
}

export function UserLogout(param) {
    return async (dispatch) => {
        dispatch({
            type: PENDING,
        });
        try {
           // const loginData = await userLogout(param).then((res) => res);
           const loginData="LogOut";
           toast.success("Logout successfully!");
            dispatch({
                type: LOGOUT_SUCCESS,
                payload: loginData
            });
            localStorage.clear();
            window.location.assign("/admin/login")
        } catch (err) {
            dispatch({
                type: ERROR,
                error: err.response
            });
        }
    };
}