
import {
    PENDING,
    ERROR,
    FETCH_REAL_ESTATE,
    FETCH_REAL_ESTATE_SUCCESS,
    UPDATE_FETCH_REAL_ESTATE,
    UPDATE_FETCH_REAL_ESTATE_SUCCESS,
    FETCH_THIRD_ESTATE,
    FETCH_THIRD_ESTATE_SUCCESS,
    FETCH_AGENTS_DROP_DAWN,
    FETCH_AGENTS_DROP_DAWN_SUCCESS,
    UPDATE_THIRD_PARTY_GET,
    UPDATE_THIRD_PARTY_GET_SUCCESS,
    FETCH_INTERNAL_LIST,
    FETCH_INTERNAL_LIST_SUCCESS,
    UPDATE_INTERNAL_GET,
    UPDATE_INTERNAL_GET_SUCCESS,
    FETCH_CRM_EXTERNAL_DROP_DAWN,
    FETCH_CRM_EXTERNAL_DROP_DAWN_SUCCESS,
    FETCH_CRM_INTERNAL_DROP_DAWN,
    FETCH_CRM_INTERNAL_DROP_DAWN_SUCCESS,
    FETCH_LOG_HISTORY,
    FETCH_LOG_HISTORY_SUCCESS,
    FETCH_LOG_HISTORY_DETAILS,
    FETCH_LOG_HISTORY_DETAILS_SUCCESS,
    FETCH_CONNECTOR_DROP_DAWN,
    FETCH_CONNECTOR_DROP_DAWN_SUCCESS,
    FETCH_CONSUMER_DROP_DAWN,
    FETCH_CONSUMER_DROP_DAWN_SUCCESS,
    FETCH_LOG_HISTORY_DETAILS_CONSUMER,
    FETCH_LOG_HISTORY_DETAILS_CONSUMER_SUCCESS,
    FETCH_LOG_DETAILS_CONSUMER,
    FETCH_LOG_DETAILS_CONSUMER_SUCCESS
} from "../actions/types";

const initialState = {
    pending: false,
    pendingHistory:false,
    error: {},
    agentsReciveData: [],
    agentsReciveDataById:{},
    thirdPartyReciveData:[],
    agentsDropDawn:[],
    thirdPartyById:{},
    internalList:[],
    internalById:{},
    crmInternalDropDawn:[],
    crmExternalDropDawn:[],
    paginationData:{},
    paginationData1:{},
    paginationData2:{},
    paginationData3:{},
    paginationData4:{},
    logHistoryData:[],
    logHistoryConsumer:[],
    logHistoryDetails:{},
    logconsumerDetail:{},
    connectorDropDawn:[],
    consumerDropDawn:[]


};

export const AgentsReducer = function (state = initialState, action) {
    switch (action.type) {
        case PENDING:
            return {
                ...state,
                pending: true,
                pendingHistory:true
            };
        case ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };

            case FETCH_REAL_ESTATE:
                return [];
              case FETCH_REAL_ESTATE_SUCCESS:
          //console.log("this is log for reducer",action.payload);
                return {
                  ...state,
                  pending: false,
                  paginationData:{...action.payload},
                  agentsReciveData: [...action.payload.rows],
                };
                case UPDATE_FETCH_REAL_ESTATE:
                    return [];
                  case UPDATE_FETCH_REAL_ESTATE_SUCCESS:
                    return {
                      ...state,
                      pending: false,
                      agentsReciveDataById: {...action.payload},
                    };

                    case FETCH_THIRD_ESTATE:
                    return [];
                  case FETCH_THIRD_ESTATE_SUCCESS:
                   //console.log("second one/////", action.payload);
                    return {
                      ...state,
                      pending: false, 
                      paginationData1:{...action.payload},                 
                      thirdPartyReciveData: [...action.payload.rows],
                    
                    };

                    // case FETCH_THIRD_ESTATE:
                    //   return [];
                    // case FETCH_THIRD_ESTATE_SUCCESS:
                    //   console.log("second one/////", action.payload);
                    //   return {
                    //     ...state,
                    //     pending: false,
                    //    // thirdPartyReciveData: [...action.payload.rows],
                    //     paginationData:{...action.payload.count}
                    //   };
                    case FETCH_AGENTS_DROP_DAWN:
                    return [];
                  case FETCH_AGENTS_DROP_DAWN_SUCCESS:
                    return {
                      ...state,
                      pending: false,
                      agentsDropDawn: [...action.payload],
                    };
                    case UPDATE_THIRD_PARTY_GET:
                      return [];
                    case UPDATE_THIRD_PARTY_GET_SUCCESS:
                      //console.log("this is this is third party",action.payload);
                      return {
                        ...state,
                        pending: false,
                        thirdPartyById: {...action.payload},
                      };
                      case FETCH_INTERNAL_LIST:
                        return [];
                      case FETCH_INTERNAL_LIST_SUCCESS:
                       // console.log("this is this is third party",action.payload);
                        return {
                          ...state,
                          pending: false,
                          paginationData2:{...action.payload},   
                          internalList: [...action.payload.rows],
                        };

                        case UPDATE_INTERNAL_GET:
                          return [];
                        case UPDATE_INTERNAL_GET_SUCCESS:
                         // console.log("this is this is third party",action.payload);
                          return {
                            ...state,
                            pending: false,
                            internalById: {...action.payload},
                          };

                          case FETCH_CRM_EXTERNAL_DROP_DAWN:
                            return [];
                          case FETCH_CRM_EXTERNAL_DROP_DAWN_SUCCESS:
                            return {
                              ...state,
                              pending: false,
                              crmExternalDropDawn: [...action.payload],
                            };
                            case FETCH_CRM_INTERNAL_DROP_DAWN:
                              return [];
                            case FETCH_CRM_INTERNAL_DROP_DAWN_SUCCESS:
                              return {
                                ...state,
                                pending: false,
                                crmInternalDropDawn: [...action.payload],
                              };
                              case FETCH_LOG_HISTORY:
                                return [];
                              case FETCH_LOG_HISTORY_SUCCESS:
                                return {
                                  ...state,
                                  pending: false,
                                  paginationData3:{...action.payload}, 
                                  logHistoryData: [...action.payload.rows],
                                };

                                case FETCH_LOG_HISTORY_DETAILS_CONSUMER:
                                  return [];
                                case FETCH_LOG_HISTORY_DETAILS_CONSUMER_SUCCESS:
                                  return {
                                    ...state,
                                    pending: false,
                                    paginationData4:{...action.payload}, 
                                    logHistoryConsumer: [...action.payload.rows],
                                  };

                                case FETCH_LOG_HISTORY_DETAILS:
                                  return [];
                                case FETCH_LOG_HISTORY_DETAILS_SUCCESS:
                                  return {
                                    ...state,
                                    pendingHistory: false,
                                    pending: false,
                                    logHistoryDetails: {...action.payload},
                                  };
                                  case FETCH_LOG_DETAILS_CONSUMER:
                                    return [];
                                  case FETCH_LOG_DETAILS_CONSUMER_SUCCESS:
                                    //console.log("this is log.... redex",...action.payload);
                                    return {
                                      ...state,
                                      pendingHistory: false,
                                      pending: false,
                                      logconsumerDetail: {...action.payload},
                                    };

                                  case FETCH_CONNECTOR_DROP_DAWN:
                                  return [];
                                case FETCH_CONNECTOR_DROP_DAWN_SUCCESS:
                                  return {
                                    ...state,
                                    pending: false,
                                    pendingHistory: false,
                                    connectorDropDawn: [...action.payload.rows],
                                  };

                                  case FETCH_CONSUMER_DROP_DAWN:
                                    return [];
                                  case FETCH_CONSUMER_DROP_DAWN_SUCCESS:
                                    return {
                                      ...state,
                                      pending: false,
                                      pendingHistory: false,
                                      consumerDropDawn: [...action.payload.rows],
                                    };
       
        default:
            return state;
    }
};
