import React from "react";
const Loading = (props) => {
  return (
    // <div className="data-loader">
    //   <div className="progress-bar">
    //     <span className="bar">
    //       <span className="progress"></span>
    //     </span>
    //   </div>
    // </div>
    <div className="sk-circle-wrap">
      <div className="sk-circle">
        <div className="sk-circle1 sk-child"></div>
        <div className="sk-circle2 sk-child"></div>
        <div className="sk-circle3 sk-child"></div>
        <div className="sk-circle4 sk-child"></div>
        <div className="sk-circle5 sk-child"></div>
        <div className="sk-circle6 sk-child"></div>
        <div className="sk-circle7 sk-child"></div>
        <div className="sk-circle8 sk-child"></div>
        <div className="sk-circle9 sk-child"></div>
        <div className="sk-circle10 sk-child"></div>
        <div className="sk-circle11 sk-child"></div>
        <div className="sk-circle12 sk-child"></div>
      </div>
      Loading...
    </div>
  );
};
export default Loading;
