import React, { Component,useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import {useForm} from 'react-hook-form';
import * as Api from "../../../../data/ApiRequest";
import * as path from "../../../../data/ApiList";
import { ToastContainer, toast } from 'react-toastify';
import { useParams,useHistory } from 'react-router-dom';
import Loading from '../../../common/Loading';

const AddConnecters=(props)=>{
//   const{
//     agentid,
//       crmname,
//       environment,
//        baseurl,
//       authtokendpoint,
//       clientid,
//       clientsecretkey,
//       otherinformation
    
//   }=props.agentState
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const modifiedDate = date.toLocaleDateString('en-CA');
const [agentdata, setAgentdata] = useState([]);
const [dropdown1, setdropdown1] = useState([]);
const [dropdown2, setdropdown2] = useState([]);
const [editdata, setEditdata] = useState([]);
const [reciveStatus,setReciveStatus]= useState(true)
const [editdate, setEditdate] = useState({enablefromdate:modifiedDate});
const parameter = useParams();
const history = useHistory();
//console.log("this testing....",editdate.enablefromdate);
/*const[data, setData] = useState({
	connectorname:"",
	agentid:'',
	thirdpartycrmid:"",
	internalcrmid:""
});*/
		
	useEffect(() =>{
		getagentDropDown();
	},[]);
	const getagentDropDown = async() =>{
		
		await Api.getRequest(path.AGENTS_DROP_DAWN,'').then((res)=>{
			setAgentdata(res.data);
			setReciveStatus(false);
		});
		if(parameter.id != undefined)
		{
			await Api.getRequest(path.CONNECTOR_UPDATE_GET_URL+'/'+parameter.id,'').then((res)=>{
				setEditdata(res.data);
				setEditdate(res.data);
				reset({ connectorname:res.data.name,agentid: res.data.real_estate_agent_id,thirdpartycrmid:res.data.third_party_crm_id,internalcrmid:res.data.internal_crm_id,enablefromdate:res.data.enable_from_date })
				Api.getRequest(path.CONNECTOR_GET_ALL_DROPDOWN_URL+res.data.real_estate_agent_id,'').then((res1)=>{
					setdropdown1(res1.data.result.thirdpartycrm);
					setdropdown2(res1.data.result.internalCrmData);
				});
			});
		}		
	   }
	   async function handleChanges(event){ 
		let agentid = event.target.value;
		await Api.getRequest(path.CONNECTOR_GET_ALL_DROPDOWN_URL+agentid,'').then((res)=>{
			//const newData = {...data };
			//newData[event.target.name] = event.target.value;
			//setData(newData);  
			setdropdown1(res.data.result.thirdpartycrm);
			setdropdown2(res.data.result.internalCrmData);
		});
	   }
	   const{
		register,
		handleSubmit,
		reset,
		formState: { errors }
	   } = useForm();
	   const onSubmit = data =>
	   {  

		//console.log("calender date....",data);
			setReciveStatus(true);
			if(parameter.id != undefined)
			{  
				Api.postRequest2(path.CONNECTOR_UPDATE_URL+"/"+parameter.id,data).then((res)=>{
					//console.log("data:",res.data.error);
					setReciveStatus(false);
					if(res.data.error == false)
					{
						toast.success("Connector has been updated successfully.",{
							position: toast.POSITION.TOP_CENTER
						});
						setTimeout(function(){
							history.push('/admin/listconnecters');
						},5000)
					}
					else if(res.data.error == true)
					{
						toast.error("Connector details is already exists.",{
							position: toast.POSITION.TOP_CENTER
						});
					}
					else
					{
						toast.error("Something went wrong.Please try again.",{
							position: toast.POSITION.TOP_CENTER
						});
					}
					
				});
			}
			else
			{
				Api.postRequest2(path.CONNECTOR_ADD_URL,data).then((res)=>{
					setReciveStatus(false);
					if(res.data.error == false)
					{
						toast.success("Connector has been added successfully.",{
							position: toast.POSITION.TOP_CENTER
						})
					}
					else if(res.data.error == true)
					{
						toast.error("Connector details is already exists.",{
							position: toast.POSITION.TOP_CENTER
						});
					}
					else
					{
						toast.error("Something went wrong.Please try again.",{
							position: toast.POSITION.TOP_CENTER
						});
					}
					reset();
				});
			}
	   }	
	   let editRealEstateValue = editdata.real_estate_agent_id || "DEFAULT";
	   let editThirdPartyValue = editdata.third_party_crm_id || "DEFAULT";
	   let editInternalValue = editdata.internal_crm_id || "DEFAULT";   
  return (
    <>
       {reciveStatus ===true && <Loading/>}
       <nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item"><a href="#" className="breadcrumb-link">Dashboard</a></li>
						<li className="breadcrumb-item active" aria-current="page">Add Connector</li>
					</ol>
				</nav>
				<form method="POST" id="AddConnector" onSubmit={handleSubmit(onSubmit)}>
				<div className="card">
					<div className="card-body">
						<div className="mb-3">
							<h5 className="hed01">{(parameter.id != undefined) ? 'Edit':'Add'} Connector <span className="tx11"></span></h5>
							
						</div>	
											
						
						<div className="accordion" id="accordionExample1">

						<div className="card bx_conect">
								<div className="header" id="headingOne1">
									<h2 className="mb-0">
										
										<button className="d-flex btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne1" aria-expanded="false" aria-controls="collapseOne">
										<i className="fal fa-angle-down"></i>
											{/*<span className="bxre"><img src="../../../assets/images/icon_agent.jpg" alt="" /></span>*/}
											<div className="hetx01">1. Choose Connector Name</div>
										</button>
									</h2>
								</div>

								<div id="collapseOne1" className="collapse" aria-labelledby="headingOne1" data-parent="#accordionExample1">
									<div className="card-body">
										<input type="text" defaultValue ={editdata.name} {...register("connectorname", { required:true })}  className="form-control" />
										{errors.connectorname && errors.connectorname.type == "required" && <div className="errorMsg">Connector name is required.</div>}
									</div>
								</div>
							</div>

							<div className="card bx_conect">
								<div className="header" id="headingOne">
									<h2 className="mb-0">
										
										<button className="d-flex btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
										<i className="fal fa-angle-down"></i>
											{/*<span className="bxre"><img src="../../../assets/images/icon_agent.jpg" alt="" /></span>*/}
											<div className="hetx01">2. Choose Real Estate Agent</div>
										</button>
									</h2>
								</div>								
								<div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
									<div className="card-body">
										<select defaultValue={editRealEstateValue} {...register("agentid", { required:true })} onChange = {handleChanges} id="agentid" className="form-control">
											<option value="">Select Real Estate Agent</option>
											{agentdata.map((data,index)=>(
											<option selected={data.id === editdata.real_estate_agent_id} value={data.id} key={index}>{data.agentName}</option>											
											))}
										</select>
										{errors.agentid && errors.agentid.type == "required" && <div className="errorMsg">Real Estate Agent is required.</div>}
									</div>
								</div>
							</div>
							<div className="card bx_conect">
								<div className="header" id="headingTwo">
									<h2 className="mb-0">
										
										<button className="d-flex btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
											<i className="fal fa-angle-down"></i>
											{/*<span className="bxre"><img src="../../../assets/images/logo_vultre.png" alt="" /></span>*/}
											<div className="hetx01"><span>Trigger</span> 3. Choose Third Party CRM</div>
										</button>
									</h2>
								</div>
								<div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
									<div className="card-body">
										<select defaultValue={editThirdPartyValue} {...register("thirdpartycrmid", { required:true })}  className="form-control" style={{display:"inline-block", maxwidth: "360px"}}>
											<option value="">Select Third Party CRM</option>
											{dropdown1.map((data1,index1)=>(
											<option selected={data1.id === editdata.third_party_crm_id} value={data1.id} key={index1}>{data1.cf_crm.crmName}</option>											
											))}
										</select>&nbsp;&nbsp;
										{errors.thirdpartycrmid && errors.thirdpartycrmid.type == "required" && <div className="errorMsg">Third Party CRM is required.</div>}
									{/*<select className="form-control" style={{display:"inline-block", maxwidth:"360px"}}>
											<option>Select Webhook </option>
											
										</select>*/}
									</div>
								</div>
							</div>
							<div className="card bx_conect">
								<div className="header" id="heading3">
									<h2 className="mb-0">
										
										<button className="d-flex btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapse3" aria-expanded="false" aria-controls="collapseTwo">
											<i className="fal fa-angle-down"></i>
											{/*<span className="bxre"><img src="../../../assets/images/logo_vultre.png" alt="" /></span>*/}
											<div className="hetx01"><span>Action</span> 4. Choose Internal CRM</div>
										</button>
									</h2>
								</div>
								<div id="collapse3" className="collapse" aria-labelledby="heading3" data-parent="#accordionExample">
									<div className="card-body">
										<select defaultValue={editInternalValue} {...register("internalcrmid", { required:true })}  className="form-control" style={{display:"inline-block", maxwidth:"360px"}}>
											<option value="">Select Internal CRM</option>
											{dropdown2.map((data2,index2)=>(
											<option selected={data2.id === editdata.internal_crm_id} value={data2.id} key={index2}>{data2.cf_crm.crmName}</option>											
											))}
										</select>&nbsp;&nbsp;
										{errors.internalcrmid && errors.internalcrmid.type == "required" && <div className="errorMsg">Internal CRM is required.</div>}
										{/*<select className="form-control" style={{display:"inline-block", maxwidth:"360px"}}>
											<option>Select Event </option>
											
										</select>*/}
									</div>
								</div>
							</div>
							<div className="card bx_conect">
								<div className="header" id="heading3">
									<h2 className="mb-0">
										
										<button className="d-flex btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseTwo">
											<i className="fal fa-angle-down"></i>
											{/*<span className="bxre"><img src="../../../assets/images/logo_vultre.png" alt="" /></span>*/}
											<div className="hetx01"><span>Date</span> 5.  Interation Start Date</div>
										</button>
									</h2>
								</div>
								<div id="collapseFive" className="collapse" aria-labelledby="heading3" data-parent="#accordionExample">
									<div className="card-body">
										<input type="date" defaultValue ={editdate.enablefromdate}  {...register("enablefromdate", { required:true })}  className="form-control" />
										
										{errors.enablefromdate && errors.enablefromdate.type == "required" && <div className="errorMsg">Date is required.</div>}
						
									</div>
								</div>
							</div>
							{/*<div className="card bx_conect">
								<div className="header" id="heading4">
									<h2 className="mb-0">
										
										<button className="d-flex btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapse4" aria-expanded="false" aria-controls="collapseTwo">
											<i className="fal fa-angle-down"></i>
											<span className="bxre"><img src="../../../assets/images/ico_campaignflow.png" alt="" /></span>
											<div className="hetx01"><span>Action</span> 4. 
											<input style={{width:"530px", maxwidth:"none"}} disabled className="form-control editxt" type="text" value="Add Property Data to Campaignflow for Listed Data from Vault"/> <a className="btn_edit" href="#"><i className="fal fa-pencil"></i></a>
											</div>
										</button>
									</h2>
								</div>
								<div id="collapse4" className="collapse" aria-labelledby="heading4" data-parent="#accordionExample">
									<div className="card-body">
										<select className="form-control" style={{display:"inline-block", maxwidth:"360px"}}>
											<option>Select Internal CRM</option>
											
										</select>&nbsp;&nbsp;
										<select className="form-control" style={{display:"inline-block", maxwidth:"360px"}}>
											<option>Select Event </option>
											
										</select>
									</div>
								</div>
							</div>*/}
						</div>
					</div>
				</div>
				<div className="down_pan">
					<div className="text-right">
						<Link to="/admin/listconnecters"><button className="btn_cf dim"><i className="fa fa-times" aria-hidden="true">&nbsp;</i> Cancel</button></Link> &nbsp;
						
                            <input className="btn_cf scss" type="submit" name="Save" value="Save" />
					</div>
				</div>
				</form>
				
            

    </>
)
                        
      }
export default AddConnecters;