import React, { Component } from "react";
import { Form, Button } from "react-bootstrap";
class Pagination extends Component {
  state = {
    pageNumber: 1,
  };
  /**
   * It handle onClick event of Previous button
   */
  handlePrevClick = (e) => {
    e.preventDefault();

    let { pageNumber, pageSize } = this.props;
    let page = +pageNumber - 1;
    pageNumber = page;
    this.setState({ pageNumber: pageNumber });
    this.props.updateView(pageNumber, pageSize);
  };

  /**
   *
   * @param {*} e
   */
  handleNextClick = (e) => {
    e.preventDefault();
    let { pageNumber, pageSize } = this.props;
    let page = +pageNumber + 1;
    pageNumber = page;
    this.setState({ pageNumber: pageNumber });
    this.props.updateView(pageNumber, pageSize);
  };

  /**
   *
   * @param {*} e
   */
  handlePageChangeValue = (e) => {
    //console.log("this is pagenumber",e.target.value);
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      e.preventDefault();
      this.setState({ ...this.state, pageNumber: +e.target.value });
    }
  };

  /**
   *
   */
  goToPage = () => {
    const { pageNumber } = this.state;
    let { pageSize } = this.props;
    this.props.updateView(pageNumber, pageSize);
  };

  /**
   *
   * @param {*} e
   */
  handleBtnClick = (e) => {
    e.preventDefault();
    let page = +e.target.id;
    let { pageNumber, pageSize } = this.props;
    pageNumber = page;
    this.setState({ pageNumber: page });
    this.props.updateView(pageNumber, pageSize);
  };

  /**
   *
   * @param {*} e
   */
  handleSizeChange = (e) => {
    const { totalElements } = this.props;
    e.preventDefault();
    const size = +e.target.value;
    if (size >= +totalElements) {
      this.props.updateView(1, size);
    } else if (size <= +totalElements) {
      this.props.updateView(1, size);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.pageNumber !== prevProps.pageNumber) {
      this.setState({ pageNumber: this.props.pageNumber });
    }
  }

  render() {
    let upperPageBound = 5,
      lowerPageBound = 0,
      pageBound = 5;
    const { totalElements, totalPages, pageNumber, pageSize } = this.props;
    upperPageBound = upperPageBound * Math.ceil(pageNumber / pageBound);
    lowerPageBound = upperPageBound - pageBound;

    // array to hold all the page numbers
    // we are using it to display page number buttons
    const pageNumbers = [];
    let current = parseInt(pageNumber);
    let elements = pageSize;
    let last = totalPages;
    // console.log("elements", elements);
    // find currently what record numbers we are displaying (eg. 1 to 10 or 10  to 20)
    const from = (current - 1) * elements + 1;
    let to = from + (elements - 1);
    to = to > totalElements ? totalElements : to;

    // console.log("from ", from);

    // logic to find total page numbers
    for (let i = 2; i <= Math.ceil(totalElements / elements) - 1; i++) {
      pageNumbers.push(i);
    }

    // Logic to descide what page numbers should display
    const renderPageNumbers = pageNumbers.map((number, i) => {
      // if current page number is 2 then render it
      if (+number > 1 && +number === 2 && +current === 2) {
        return (
          <li
            key={i}
            className={`page-item ${number === current ? "active" : ""}`}
          >
            <a
              id={number}
              className="page-link"
              href="#"
              onClick={this.handleBtnClick}
            >
              {number}
            </a>
          </li>
        );
        // logic to display other page number buttons
      } else if (
        +number > 1 &&
        +number < +upperPageBound + 1 &&
        +number > +lowerPageBound
      ) {
        return (
          <li
            key={i}
            className={`page-item ${number === current ? "active" : ""}`}
          >
            <a
              id={number}
              className="page-link"
              href="#"
              onClick={this.handleBtnClick}
            >
              {number}
            </a>
          </li>
        );
      }
    });

    // when page numbers length is greater than upperPageBound then display hellip on the right side
    let pageIncrementBtn = null;
    if (upperPageBound < this.props.totalPages) {
      pageIncrementBtn = (
        <li className="page-item">
          <a className="page-link" href="#">
            &hellip;
          </a>
        </li>
      );
    }

    // when lowerPageBound is greater than or equal to 2 then display hellip on the left side
    let pageDecrementBtn = null;
    if (lowerPageBound >= 2) {
      pageDecrementBtn = (
        <li className="page-item">
          <a className="page-link" href="#">
            &hellip;
          </a>
        </li>
      );
    }

    return (
      <div className="border_tbl_pagination">
        {/* <div className="pagination-count">
          <select
            className="form-control"
            value={pageSize}
            onChange={this.handleSizeChange}
          >
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={200}>200</option>
          </select>
          <p className="show-record">{`Showing ${from} to ${to} of ${totalElements} entries`}</p>
          <p className="total-entries">{`Total ${totalElements} records found`}</p>
          <div className="go-page go-page-mobile">
            <span className="mr-1">Go to page</span>
            <Form.Control
              type="text"
              value={this.state.pageNumber || ""}
              pattern="\d*"
              maxLength={3}
              onChange={(e) => this.handlePageChangeValue(e)}
            />
            <Button
              disabled={
                +this.state.pageNumber > +last || !this.state.pageNumber
              }
              type="button"
              variant="btn ml-2"
              onClick={() => this.goToPage()}
            >
              Go <img alt="img" src="/assets/images/blue-down-arrow.svg" />
            </Button>
          </div>
        </div> */}
        <nav className="pagination-number" aria-label="...">
          <ul className="pagination">
            <li className={`page-item ${current === 1 ? "disabled" : ""}`}>
              <a
                className="page-link"
                href="#"
                tabIndex="-1"
                onClick={this.handlePrevClick}
              >
                «
              </a>
            </li>
            <li className={`page-item ${current === 1 ? "active" : ""}`}>
              <a
                id={1}
                className="page-link"
                href="#"
                onClick={this.handleBtnClick}
              >
                1
              </a>
            </li>
            {pageDecrementBtn}
            {renderPageNumbers}
            {pageIncrementBtn}
            {totalPages !== 1 && (
              <li className={`page-item ${last === current ? "active" : ""}`}>
                <a
                  id={last}
                  className="page-link"
                  href="#"
                  onClick={this.handleBtnClick}
                >
                  {last}
                </a>
              </li>
            )}

            <li className={`page-item ${current === last ? "disabled" : ""}`}>
              <a className="page-link" href="#" onClick={this.handleNextClick}>
                »
              </a>
            </li>
          </ul>
          {/* <div className="go-page">
            <span className="mr-1">Go to page</span>
            <Form.Control
              type="text"
              value={this.state.pageNumber || ""}
              pattern="\d*"
              maxLength={3}
              onChange={(e) => this.handlePageChangeValue(e)}
            />
            <Button
              disabled={
                +this.state.pageNumber > +last || !this.state.pageNumber
              }
              type="button"
              variant="btn ml-2"
              onClick={() => this.goToPage()}
            >
              Go <i className="fas fa-chevron-right"></i>
              {/* <img alt="img" src="/assets/images/blue-down-arrow.svg"/> 
            </Button>
          </div> */}
        </nav>
      </div>
    );
  }
}

export default Pagination;