import { isEmail, isLength } from "validator";
import messages from "../common/messages";

export default function validateLoginForm({ email, password }) {
    let errors = {};
    if (!email) {
        errors.email = messages["username.required"];
    } else if (!isEmail(email) && !isLength(email, { min: 10, max: 50 })) {
        errors.email = messages["username.invalid"];
    }
    if (!password) {
        errors.password = messages["password.required"];
    }
    return errors;
}