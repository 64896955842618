import { isEmail, isLength } from "validator";
import messages from "../common/messages";

export default function validateInternalCrmForm({  agentid,
    crmname,
    environment,
     baseurl,
    authtokendpoint,
    clientid,
    clientsecretkey,
    otherinformation ,updateStatusTime}) {
    let errors = {};
    if (!agentid) {
        errors.agentid = messages["agentid.required"];
    } 
 
      if (!crmname) {
        errors.crmname = messages["crmname.required"];
    }
    if (!environment) {
        errors.environment = messages["environment.required"];
    }
    if (!baseurl) {
        errors.baseurl = messages["baseurl.required"];
    }
    if (!authtokendpoint) {
        errors.authtokendpoint = messages["authtokendpoint.required"];
    }
    if (!clientid) {
        errors.clientid = messages["clientid.required"];
    }
    if (!clientsecretkey) {
        errors.clientsecretkey = messages["clientsecretkey.required"];
    }
    // if (!otherinformation) {
    //     errors.otherinformation = messages["otherinformation.required"];
    // }
    if (!updateStatusTime) {
        errors.updateStatusTime = messages["updateStatusTime.required"];
    }
    return errors; 
}