import React, { Component } from 'react';
import { Link } from "react-router-dom";

const ListInternalCrm = (props) => {
    // console.log("this list page ",props.agentsReciveData);
    return (


        <>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#" className="breadcrumb-link">Dashboard</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Internal CRM</li>
                </ol>
            </nav>


            <div className="card">
                <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between mb-3">
                        <h5 className="hed01">Internal CRM</h5>
                        <input className="form-control serch_hed"
                         type="searchText"
                         value={props.searchText}
                         onChange={(e) => {
                           props.searchHandleChange(e);
                         }}
                          placeholder="Search.."
                         />
                        <Link to='../admin/listinternalcrm/addinternalcrm' className="btn btn-info rounded"><i className="fal fa-plus"></i> Add</Link>
                    </div>

                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Rel Estate Agent</th>
                                <th scope="col">CRM Name</th>
                                <th scope="col">Environment </th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>

                            {props.internalList && props.internalList.length > 0 ? (
                                props.internalList.map((item, index) => (
                                    <tr key={index}>
                                        <th scope="row">{item.id}</th>
                                        <td>{item.cf_real_estate_agent?.agentName}</td>
                                        <td>{item.cf_crm?.crmName}</td>
                                        <td>Live</td>
                                        <td> <a href="#" onClick={() => props.onEditClick(item.id)}><Link to={`/admin/listinternalcrm/addinternalcrm/edit/${item.id}`} title="Edit" className="btn btn-sm btn-outline-dark">
                                            <i className="fal fa-pencil" ></i></Link></a>
                                            <a href='#'
                                             title="Remove" 
                                             onClick={() =>props.openDeleteModal(item.id,1)}
                                             data-toggle="modal"
                                             data-target="#detele-popup"
                                            
                                            ><i className="far fa-trash"></i></a></td></tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={5}>
                                        <div className="no-data text-center">
                                            {/* <img src="/assets/images/empty-box.svg" /> */}
                                            <h5>No Data Found</h5>
                                        </div>
                                    </td>
                                </tr>
                            )}





                        </tbody>
                    </table>

                </div>
            </div>



        </>
    );

}
export default ListInternalCrm;