import { isEmail, isLength } from "validator";
import messages from "../common/messages";

export default function validateThiredPartyForm({ agentid,
    crmname,
    environment,
    apiendpoints,
    apikeys,
    apitokens,
    otherinformations,
    accountid,
    is_schedule,
    schedule_start_time,
    schedule_interval,
})
   
    {
       // console.log("this is value....",crmname);
    let errors = {};
    if (!agentid) {
        errors.agentid = messages["agentid.required"];
    } 
 
      if (!crmname) {
        errors.crmname = messages["crmname.required"];
    }
    if (!environment) {
        errors.environment = messages["environment.required"];
    }
    if (!apikeys) {
        errors.apikeys = messages["apikeys.required"];
    }
    if (!apiendpoints) {
        errors.apiendpoints = messages["apiendpoints.required"];
    }
    if (!apitokens) {
        errors.apitokens = messages["apitokens.required"];
    }
    // if (!otherinformations) {
    //     errors.otherinformations = messages["otherinformations.required"];
    // }
    if(crmname!="7"){
        if (!accountid) {
            errors.accountid = messages["accountid.required"];
        }
    }

    if(is_schedule=="1"){
        if (!schedule_start_time) {
            errors.schedule_start_time = messages["schedule_start_time.required"];
        }
    }
    if(is_schedule=="1"){
        if (!schedule_interval) {
            errors.schedule_interval = messages["schedule_interval.required"];
        }
    }
   
    return errors; 
}