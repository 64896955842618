import React, { Component,useState,useEffect,useMemo } from 'react';
import { Link } from "react-router-dom";
import * as Api from "../../../../data/ApiRequest";
import * as path from "../../../../data/ApiList";
import { ToastContainer, toast } from 'react-toastify';
import Loading from '../../../common/Loading';
import Pagination from "../Pagination/Pagination"
import { confirmAlert } from 'react-confirm-alert';
import "../../../../../src/react-confirm-alert.css";
let PageSize = 50;

const ListConnectrs = (props) => {		
	const [page, setPage] = useState([]);
	const[keysearch,setKeysearch] = useState([]);
	const [totalrecord, setTotalrecord] = useState([]);
	const [reciveStatus,setReciveStatus]= useState(true)
	useEffect(() =>{
		getContent();
	},[]);
	const getContent = (keyword='',numberpage=1) =>{ 
		setReciveStatus(true)
		 Api.getRequest(path.CONNECTOR_LIST_URL+keyword+'&page='+numberpage+'&size='+PageSize,'').then((res)=>{
			setPage(res.data.rows);
			setTotalrecord(res.data.count)
			setReciveStatus(false)
		});
	   }
	   const [currentPage, setCurrentPage] = useState(1);
		const currentPosts = useMemo(() => {			
			getContent(keysearch,currentPage);				
		}, [currentPage]);

	   function handleChange(event)
	   {
			let searchKey = event.target.value;
			setKeysearch(searchKey);
			getContent(searchKey);
	   }	
	   const deleteconnector = async(e)  =>{
		confirmAlert({
			title: 'Are you sure ?',
			message: 'you want to delete this!',
			buttons: [
			  {
				label: 'Yes,Delete',
				onClick: () => {
					let id= e.target.getAttribute("id");
					Api.getRequest(path.CONNECTOR_DELETE_URL+id,'').then((res)=>{
						if(res.data.error == false)
						{
							getContent();
							toast.success("Connector has been deleted successfully.",{
								position: toast.POSITION.TOP_CENTER
							});					
						}				
						else
						{
							toast.error("Something went wrong.Please try again.",{
								position: toast.POSITION.TOP_CENTER
							});
						}	
					});
				}
			  },
			  {
				label: 'Cancel',
			  }
			]
		  });			
	   } 	   
	   const changeStatus = async(e)  =>{ 
	   let id= e.target.getAttribute("dataid");
	   let status= e.target.getAttribute("data1");
	   await Api.getRequest(path.CONNECTOR_STATUS_UPDATE_URL+id+'/'+status,'').then((res)=>{
		   if(res.data.error == false)
		   {
			   if(status ==1)
			   {
				   var msg = "Consumer has been enabled successfully.";
			   }
			   else{
				   var msg = "Consumer has been disabled successfully.";
			   }
			   getContent();
			   toast.success(msg,{
				   position: toast.POSITION.TOP_CENTER
			   });					
		   }				
		   else
		   {
			   toast.error("Something went wrong.Please try again.",{
				   position: toast.POSITION.TOP_CENTER
			   });
		   }	
	   });
   }
    return (


        <>
				{reciveStatus ===true && <Loading/>} 
                   <nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item"><a href="#" className="breadcrumb-link">Dashboard</a></li>
						<li className="breadcrumb-item active" aria-current="page">Connectors</li>
					</ol>
				</nav>
				<div className="card">
					<div className="card-body">
						<div className="mb-1">
							<h5 className="hed01">Connectors</h5>
						</div>	
						<div className="d-flex align-items-center justify-content-between mb-3">
							<input className="form-control serch_hed" onChange={handleChange} type="text" placeholder="Search.."/>
							<Link to="../admin/listconnecters/addconnecters" className="btn btn-cf01 rounded"><i className="far fa-edit"></i> Create Connector</Link>
						</div>
						{page.length >0 ? page.map((data,index) =>(
							
						<div className="mb-3 bx_conect">
							<div key={index} className="d-flex align-items-center justify-content-between cntop">
								{/*<div className="logo_blk">
									
									<span className="l_sq"><img src="../../../assets/images/logo_vultre.png" alt="" /></span>
									<span className="l_sq"><img src="../../../assets/images/ico_campaignflow.png" alt="" /></span>
								</div>	*/}
								<span className="nem" style={{ textTransform: 'capitalize' }}>{data.name}</span>
								<span className="nem">{data.added_date}</span>
								<div className="smile-rating-container">
									<form className="submit-rating">
										<input id="off" onClick={changeStatus} checked={(data.status==0)?"checked":''} dataId ={data.id} data1={(data.status==0)? 1 :0} name="satisfaction" type="radio" /> 
										<input checked={(data.status==1)?"checked":''} onClick={changeStatus} dataId ={data.id} data1={(data.status==1)? 0 :1} id="on" name="satisfaction" type="radio" /> 
										<label for="off" className="rating-label rating-label-meh">Off</label>
										<div className="smile-rating-toggle"></div>
										<div className="toggle-rating-pill"></div>
										<label for="on" onClick={changeStatus} className="rating-label rating-label-fun">On</label>
									</form>
								</div>
								
								<div className="dropdown d-inline d-inline-block lookdd">
									<button className="dd_show dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										<span></span>
										<span></span>
										<span></span>
									</button>
									
									<div className="dropdown-menu dropdown-menu-right">
										{/*<a className="dropdown-item" href="#"><i className="fal fa-eye"></i> View</a>*/}
										<Link to={`/admin/listconnecters/addconnectors/edit/${data.id}`} title="Edit" className="dropdown-item">
										<i className="far fa-list"></i> Details</Link>
										{/*<a className="dropdown-item" href="#"><i className="fal fa-history"></i> History</a>
										<a className="dropdown-item" href="#"><i className="fal fa-pen-alt"></i> Rename</a>*/}
										<Link className="dropdown-item" id={data.id} onClick={deleteconnector}><i className="fal fa-trash-alt"></i> Delete</Link>
									</div>
								</div>								
							</div>							
						</div>
						
						)):
						<div className="no-data">
							<h5>No Data Found</h5>
						</div>
						}
						<Pagination
							className="pagination-bar"
							currentPage={currentPage}
							totalCount={totalrecord}
							pageSize={PageSize}
							onPageChange={page => setCurrentPage(page)}
						/>	
						{/* <div className="mb-3 bx_conect">
							<div className="d-flex align-items-center justify-content-between cntop">
								<div className="logo_blk">
									
									<span className="l_sq"><img src="../../../assets/images/logo_vultre.png" alt="" /></span>
									<span className="l_sq"><img src="../../../assets/images/ico_campaignflow.png" alt="" /></span>
								</div>	
								<span className="nem">PROSPECT or NOT CURRENTLY LISTED STATUS Property from Vault to Campaignflow</span>
								
								<div className="smile-rating-container">
									<form className="submit-rating">
										<input checked="checked" id="off"  name="satisfaction" type="radio" /> 
										<input id="on" name="satisfaction" type="radio" /> 
										<label for="off" className="rating-label rating-label-meh">Off</label>
										<div className="smile-rating-toggle"></div>
										<div className="toggle-rating-pill"></div>
										<label for="on" className="rating-label rating-label-fun">On</label>
									</form>
								</div>
								
								<div className="dropdown d-inline d-inline-block lookdd">
									<button className="dd_show dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										<span></span>
										<span></span>
										<span></span>
									</button>
									
									<div className="dropdown-menu dropdown-menu-right">
										<a className="dropdown-item" href="#"><i className="fal fa-eye"></i> View</a>
										<a className="dropdown-item" href="#"><i className="far fa-list"></i> Details</a>
										<a className="dropdown-item" href="#"><i className="fal fa-history"></i> History</a>
										<a className="dropdown-item" href="#"><i className="fal fa-pen-alt"></i> Rename</a>
										<a className="dropdown-item" href="#"><i className="fal fa-trash-alt"></i> Delete</a>
									</div>
								</div>
							</div>
							
						</div>
						
						<div className="mb-3 bx_conect">
							<div className="d-flex align-items-center justify-content-between cntop">
								<div className="logo_blk">
									
									<span className="l_sq"><img src="../../../assets/images/logo_vultre.png" alt="" /></span>
									<span className="l_sq"><img src="../../../assets/images/ico_campaignflow.png" alt=""/></span>
								</div>	
								<span className="nem">Settlement Date Change Property from Vault to Campaignflow</span>
								
								<div className="smile-rating-container">
									<form className="submit-rating">
										<input id="off" name="satisfaction" type="radio" /> 
										<input checked="checked" id="on" name="satisfaction" type="radio" /> 
										<label for="off" className="rating-label rating-label-meh">Off</label>
										<div className="smile-rating-toggle"></div>
										<div className="toggle-rating-pill"></div>
										<label for="on" className="rating-label rating-label-fun">On</label>
									</form>
								</div>
								
								<div className="dropdown d-inline d-inline-block lookdd">
									<button className="dd_show dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										<span></span>
										<span></span>
										<span></span>
									</button>
									
									<div className="dropdown-menu dropdown-menu-right">
										<a className="dropdown-item" href="#"><i className="fal fa-eye"></i> View</a>
										<a className="dropdown-item" href="#"><i className="far fa-list"></i> Details</a>
										<a className="dropdown-item" href="#"><i className="fal fa-history"></i> History</a>
										<a className="dropdown-item" href="#"><i className="fal fa-pen-alt"></i> Rename</a>
										<a className="dropdown-item" href="#"><i className="fal fa-trash-alt"></i> Delete</a>
									</div>
								</div>
							</div>
							
						</div> */}
						
					</div>
				</div>
        </>
    )
}

export default ListConnectrs;