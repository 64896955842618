import React, { Component } from "react";
import { connect } from "react-redux";
import HomeHeader from "./HomeHeader";
import HomeFooter from "./HomeFooter";
import * as Api from "../../../../data/ApiRequest";
import * as path from "../../../../data/ApiList";
import {CountryCodeOption} from "./CountryCodeOption";
import {stateList} from './Statelist'
import {toast } from 'react-toastify'; 
import Loading from "../../../common/Loading";
import validateHomePage from "../../../validation_rules/HomePageValidation";
// import Modal  from 'react-bootstrap-modal'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
class HomePage extends Component {


   intState={
    access_token:"",
    property_address:"",
    city:"",
    first_name:"",
    last_name:"",
    Email_id:"",
    country_code:"",
    phone:"",
    funding_amount:"",
    state:"",
    termconditonCheck:false,
    enableTermStatus:"",
  
    }
    constructor() {
        super();
        this.state = {
            toggleSidebar:false,
            firstName:"",
            lastName:"",
            reciveConsumerData:{},
             directConsumState:this.intState,
             reciveStatus:false,
             errors:{},
             checkTermCondition:true,
             show:false,
             reciveStatusCode:200
           
        }
    }
   
    componentDidMount=async()=>{
      document.title ="";
   const pathRecive= this.props.location?.pathname
   var splitUrl = pathRecive.split('/postproperty/');
   this.setState({
    ...this.state,
    directConsumState: {
      ...this.state.directConsumState,
      access_token: splitUrl[1],    
    }
  })
  try{
    await Api.getRequest(path.GET_CONSUMER_DETAIL_URL+splitUrl[1]).then((res1)=>{ 
      document.title = (res1.data.title != undefined)?res1.data.title:"";
      this.setState({
        reciveConsumerData:res1.data,
        reciveStatusCode:res1.status,
        directConsumState: {
          ...this.state.directConsumState,
          enableTermStatus:res1.data.enableTermStatus,
          country_code:(res1.data.country=='AU')? "+61" :"+64"
          //footer_content:res1.data.footer_content,
          //country:res1.data.country  
        }
      })
     
      });
  }catch(error){
    this.setState({
      reciveStatusCode:error.response.status
    })
  }

  
  }

  onHandleChange =(e)=>{
   
    const { errors } = this.state;
    delete errors[e.target.name];
      this.setState({
        ...this.state,
        directConsumState: {
          ...this.state.directConsumState,
          [e.target.name]: e.target.value,
         
        }
      })
  }
  onHandleChangeChecked =(e)=>{
    const { errors } = this.state;
    delete errors[e.target.name];
      this.setState({
        ...this.state,
        directConsumState: {
          ...this.state.directConsumState,
        termconditonCheck:e.target.checked,
        }
      })
  }
 showLoader =()=>{
  this.setState({
    reciveStatus:true
  })

 }

  onSubmitConsumer =(e)=>{
   
    e.preventDefault()
    const {  access_token,
    property_address,
    city,
    first_name,
    last_name,
    Email_id,
    country_code,
    phone,
    funding_amount 
    ,termconditonCheck,enableTermStatus
   
  } = this.state.directConsumState;
    let errors = validateHomePage({
     access_token,
    property_address,
    city,
    first_name,
    last_name,
    Email_id,
    country_code,
    phone,
    funding_amount,
    termconditonCheck,
    enableTermStatus
    });
    if (Object.entries(errors).length > 0) {
    //console.log(Object.entries(errors).length);
      this.setState({
        ...this.state,
        errors: errors
      })
      
    }else {
     
      this.showLoader()

         //try{     
          Api.postRequest2(path.ADD_DIRECT_CONSUMER_DETAIL_URL,this.state.directConsumState).then((res)=>{ 
      //console.log("this is response....",res);
            if (res.data.error == false) {

              // setTimeout(function () {
              //   window.location.href=`${res.data.data}`;
              // }, 5000)

              window.location.href=`${res.data.data}`;
             this.setState({
               reciveStatus:false
             })
            //  toast.success("Send successfully.", {
            //    position: toast.POSITION.TOP_CENTER
            //  })
            
            
           }
             }).catch(error =>{
              if(error.response.data.message?.detail !==undefined){
                toast.warn(error.response.data.message.detail, {
                  position: toast.POSITION.TOP_CENTER
                })
              }else{
                toast.warn(error.response.data.message, {
                  position: toast.POSITION.TOP_CENTER
                })
              }
            
              this.setState({
                reciveStatus:false
              })

             })
         //}
        //  catch(error){
        //  console.log("error,....",error);
        //  }

    }

  
    }
  
     handleClose = () => this.setState({
   show:false   
     });
     handleShow = (e) =>{
      e.preventDefault()
      this.setState({
        show:true   
          });
     }

  onKeyPress1 =(e)=>{
    const { errors } = this.state;
    delete errors[e.target.name];
    let trimValue
    if (/^0/.test(e.target.value)) {
      //console.log("trimValue>>>>>>>>>>>>>>>>>>1");
      trimValue = e.target.value.replace(/^0/, "")
      this.setState({
        ...this.state,
        directConsumState: {
          ...this.state.directConsumState,
          [e.target.name]: e.target.value.replace(/^0/, "")
         
        }
      })
    }else{
      //console.log("trimValue>>>>>>>>>>>>>>>>>>2");
      this.setState({
        ...this.state,
        directConsumState: {
          ...this.state.directConsumState,
          [e.target.name]: e.target.value,
         
        }
      })
    }
    
      
  }

    render() {
  if(this.state.reciveConsumerData.country =="AU")
  {
    var countryCode = CountryCodeOption[0].code;   
    var countryName = CountryCodeOption[0].name;    
  }
  else
  {
    var countryCode = CountryCodeOption[1].code;   
    var countryName = CountryCodeOption[1].name;  
  }    
  const bannerStyle ={
  backgroundImage: `url(${this.state.reciveConsumerData.base_url +this.state.reciveConsumerData.banner_image})`,
  backgroundRepeat:" no-repeat",
  backgroundSize:" cover",
   paddingTop: "20rem ",
   paddingBottm: "10rem ",

        }
      const buttonColor = {
        backgroundColor: `${this.state.reciveConsumerData.color_code}`,
        fontWeight: "700",
        width: "50%",
        paddingLeft:"25px",
        paddingRight: "25px",
        color: "#000"
      }
      const hrColor = {
        width: "190px",        
        border: `4px solid ${this.state.reciveConsumerData.color_code}`,
        borderRadius: "5px"
    }
    const bulletColor = {
      color: `${this.state.reciveConsumerData.color_code}`,
  }
    const boldColor = {
      color: `${this.state.reciveConsumerData.color_code}`,
  }


   
      
        return (
       
            <React.Fragment>
              { this.state.reciveStatusCode === 200 ? (<>{this.state.reciveStatus=== true &&<Loading/>}    
         
         <HomeHeader
         reciveData={this.state.reciveConsumerData}
         />

         <section className="heroArea">
<div>
  {/* {this.state.reciveConsumerData.banner_image !== "" && ( */}
    <div className="bannerImg" style={bannerStyle}>
    </div>
  {/* )} */}
 
</div>
</section>
<section className="formArea mBtm">
 <div className="container">
     <div className="row justify-content-center">
         <div className="col-sm-7">
             <div className="formBox mx-auto" >
                 <h2 className="text-center">{this.state.reciveConsumerData.title} <br/>
                     </h2>
                     <hr className="hrStyle mx-auto" style={hrColor}/>
                     <form  onSubmit={this.onSubmitConsumer}>
                         <div className="mb-3">
                             <label for="propertyAddress" className="form-label">Property Address</label>
                             <input className="form-control" onChange={(e)=>this.onHandleChange(e)}
                              type="text" id="propertyAddress" aria-label="default input example" name="property_address"/>
                              <div className="errorMsg">{this.state.errors["property_address"]}</div>
                           </div>
                           <div className="mb-3">
                             <label for="propertyAddress" className="form-label">Suburb</label>
                             <input className="form-control" onChange={(e)=>this.onHandleChange(e)}
                              type="text" id="propertyAddress" aria-label="default input example" name="city"/>
                                <div className="errorMsg">{this.state.errors["city"]}</div>
                           </div>
                          {this.state.reciveConsumerData.country =="AU"  && (<> <div className="mb-3">
                             <label for="propertyAddress" className="form-label">State</label>
                             <select className="form-control"  aria-label="default input example"
                             onChange={(e)=>this.onHandleChange(e)}
                             name="state">
                              <option value="">Select State</option>
                           {  stateList.map((data)=>
                            <option value={data.state_code}>  {data.name}</option>
                           )}
                             </select>
                                {/* <div className="errorMsg">{this.state.errors["city"]}</div> */}
                           </div> </>)}
                           

                           <div className="mb-3">
                             <label for="inputFirstName" className="form-label">First Name</label>
                             <input className="form-control" onChange={(e)=>this.onHandleChange(e)} type="text"
                              id="inputFirstName" aria-label="default input example" name="first_name"/>
                               <div className="errorMsg">{this.state.errors["first_name"]}</div>
                           </div>
                           <div className="mb-3">
                             <label for="inputLastName" className="form-label">Last Name</label>
                             <input className="form-control"  type="text" 
                             onChange={(e)=>this.onHandleChange(e)} id="inputLastName" aria-label="default input example" name="last_name"/>
                                <div className="errorMsg">{this.state.errors["last_name"]}</div>
                           </div>
                         <div className="mb-3">
                           <label for="exampleInputEmail1" className="form-label">Email</label>
                           <input type="text" className="form-control" onChange={(e)=>this.onHandleChange(e)} 
                           id="exampleInputEmail1" aria-describedby="emailHelp" name="Email_id"/>
                            <div className="errorMsg">{this.state.errors["Email_id"]}</div>
                           {/* <!-- <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> --> */}
                         </div>
                         <div className="mb-3">
                             <label for="inputPhone" className="form-label">Phone</label>
                             <div className="d-flex align-items-center">
                             <label for="inputPhone" className="form-label phlabel">
                             { countryCode+" " +countryName}
                           </label>
                           <input type="hidden" id="inputPhone" name="country_code" value={ countryCode} />
                             {/*<select className="form-control mxw"  id="inputPhone" aria-label="default input example"
                             onChange={(e)=>this.onHandleChange(e)}                          
                             name="country_code">
                           {  CountryCodeOption.map((data)=>(
                            data.country_code == this.state.reciveConsumerData.country?(<option value={data.code}>{data.code}  {data.name}</option>):''                            
                           ))}
                             </select>*/}
                             <span className="w-100">
                             <input className="form-control" type="tel" 
                            onChange={(e)=>this.onHandleChange(e)}
                            // onChange={(e)=>this.onKeyPress1(e)}
                              id="inputPhone" aria-label="default input example" maxLength="15"  name="phone" />
                              <div className="errorMsg">{this.state.errors["phone"]}</div>
                              </span>
                              </div>
                           </div>
                           <div className="mb-3">
                             <label for="fundingAmt" className="form-label">Funding Amount Requested</label>
                             <div className="input-group">
                                 
                                 <span className="input-group-text">$</span>
                                 <input type="number" onChange={(e)=>this.onHandleChange(e)} 
                                 id="fundingAmt" className="form-control" aria-label="Amount (to the nearest dollar)" name="funding_amount"/>
                                  
                               </div>
                               <div className="errorMsg">{this.state.errors["funding_amount"]}</div>
                           </div>
                           
                       { this.state.directConsumState.enableTermStatus=== "1" && (<> <div className="mb-3 form-check">
                           <input type="checkbox" className="form-check-input" id="exampleCheck1"
                            value={this.state.directConsumState.termconditonCheck==="checked"?true:false}
                           name="termconditonCheck"  onChange={(e)=>this.onHandleChangeChecked(e)} />
                           <label className="form-check-label"  for="exampleCheck1">I have read, understood and agree to the
                            <a href="" onClick={this.handleShow}> Terms and conditions</a> above</label>                          
                         </div>
                         <div className="errorMsg">{this.state.errors["termconditonCheck"]}</div> <br></br> </>)}
                         <button type="submit" className="btn" style={buttonColor}>Get Started</button>
                       </form>
             </div>
         </div>
         <div className="col-sm-4 align-self-center">
           <div className="right-side" dangerouslySetInnerHTML={{ __html: this.state.reciveConsumerData?.page_content }}>             
           </div>
         </div>
     </div>
 </div>
</section>

<Modal show={this.state.show} onHide={this.handleClose}>
     <Modal.Header closeButton>
       <Modal.Title>Term & Condition</Modal.Title>
     </Modal.Header>
     <Modal.Body><div dangerouslySetInnerHTML={{ __html: this.state.reciveConsumerData?.term_and_conditions }}></div></Modal.Body>
     <Modal.Footer>
       <Button variant="secondary" onClick={this.handleClose}>
         Close
       </Button>
     
     </Modal.Footer>
   </Modal>




<HomeFooter data={this.state.reciveConsumerData.footer_content} /></>):(<> <div className="center-data">
            <div className="no-match container">
              <div className="error-msg">
                <h1>ERROR {this.state.reciveStatusCode}</h1>
                <h2>ACCESS FORBIDDEN</h2>
                <p>The page your are trying to access is not Authorized to you. Please contact Adminstrator.</p>
        </div>
            </div>
        </div></>)}
            
            </React.Fragment>
        );
    }
}



const mapStateToProps = (state) => {
   {}
};

HomePage.propTypes = {
   
};

export default connect(mapStateToProps, {
   
})(HomePage);

//export default HomeHeader;