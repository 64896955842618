import React from "react";
import { Container } from "react-bootstrap";
import PrivateNav from "../Auth/PrivateNav";
import Footer from "../common/Footer";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";


const CMSLayout = (props) => {
    return (
        <React.Fragment>
            <div className="dashboard-main-wrapper">
            <div className="dashboard-header">
            <Header {...props} />
            </div>
            {/* <div id="wrapper"> */}
            <div className="nav-left-sidebar sidebar-dark">
                <Sidebar {...props} />
                </div>
                <div className="dashboard-wrapper">
                    <div id="content" className="container-fluid  dashboard-content">
                        <PrivateNav {...props} />
                    </div>
                    <Footer {...props} />
                </div>
            
            </div>
        </React.Fragment>
    )
}

export default CMSLayout;