import React, { Component } from "react";
import { connect } from "react-redux";
//import profile_img from '../../img/profile-picture.png'
import jwt from 'jwt-decode'
//import LoginForm from "./LoginForm";
//import "./assets/cms_style.css"

class CMSHeader extends Component {

    constructor() {
        super();
        this.state = {
            toggleSidebar:false,
            firstName:"",
            lastName:""
        }
    }
   
    render() { 
        require("./assets/cms_style.css")   
        return (
            <React.Fragment>
             <header id="main-header">
        <div className="container">
        <div className="logo_container">
        <img src="./img/cflow-logo.png" />
        </div>
        </div> 
        </header>
            </React.Fragment>
        );
    }
}



const mapStateToProps = (state) => {
    {}  
};

CMSHeader.propTypes = {
   
};

export default connect(mapStateToProps, {
   
})(CMSHeader);

//export default CMSHeader;