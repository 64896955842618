import React, { Component, useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import "react-datetime/css/react-datetime.css";
import { timeDropDawn } from '../Comman/comman';
import Datetime from "react-datetime";
import moment from 'moment'
import Loading from '../../../common/Loading';
const AddThirdParty = (props) => {
	const[reciveStatus,setReciveStatus] = useState(true);
	const {
		agentid,
		crmname,
		environment,
		apiendpoints,
		apikeys,
		apitokens,
		otherinformations,
		accountid,
		status,
		schedule_start_time,
		schedule_interval,
		is_schedule
	} = props.agentState
	useEffect(() =>{
		setTimeout(() => {
			setReciveStatus(false);
		}, 1000);    
	},[]);
	return (		
		<>
			{reciveStatus ===true && <Loading/>}       
			<nav aria-label="breadcrumb">
				<ol className="breadcrumb">
					<li className="breadcrumb-item"><a href="#" className="breadcrumb-link">Dashboard</a></li>
					<li className="breadcrumb-item active" aria-current="page">Third Party CRM</li>
				</ol>
			</nav>
			<div className="card">
				<div className="card-body">
					<div className="d-flex align-items-center justify-content-between mb-1">
						<h5 className="hed01">Third Party CRM</h5>

					</div>
					<div className="row el_gr">
						<div className="col-sm-6 col-lg-4">
							<div className="el">
								<label>Select Real estate Agent *</label>
								<select className="form-control"
									name="agentid" value={agentid}
									onChange={(e) => props.onDataInputFieldHnadleChange(e)}
								>
									<option value=" ">--Select--</option>
									{props.agentsDropDawn && props.agentsDropDawn.length > 0 && (
										props.agentsDropDawn.map((item, index) => (
											<option key={index} value={item.id}>{item.agentName}</option>
										)))}
								</select>
								<div className="errorMsg">{props.errors["agentid"]}</div>
							</div>
						</div>
						<div className="col-sm-6 col-lg-4">
							<div className="el">
								<label>CRM Name *</label>
								{/* <input className="form-control" type="crmname" name="crmname" value={crmname}
                        onChange={(e) => props.onDataInputFieldHnadleChange(e)}/> */}

								<select className="form-control"
									name="crmname" value={crmname}
									onChange={(e) => props.onCrmFieldHnadleChange(e)}
								>
									<option value="0">--Select--</option>
									{props.crmExternalDropDawn && props.crmExternalDropDawn.length > 0 && (
										props.crmExternalDropDawn.map((item, index) => (
											<option key={index} value={+item.id}>{item.crmName}</option>
										)))}
								</select>
								<div className="errorMsg">{props.errors["crmname"]}</div>
							</div>
						</div>
						<div className="col-sm-6 col-lg-4">
							<div className="el">
								<label>Environment *</label>
								<select className="form-control"
									name="environment" value={environment}
									onChange={(e) => props.onDataInputFieldHnadleChange(e)}
								>
									<option value="1">Live</option>
									{/* <option value="2">Test</option> */}
								</select>
								<div className="errorMsg">{props.errors["environment"]}</div>
							</div>
						</div>
						<div className="col-sm-6 col-lg-4">
							<div className="el">
								<label>API EndPoint *</label>
								<input className="form-control" type="text"
									name="apiendpoints" value={apiendpoints}
									onChange={(e) => props.onDataInputFieldHnadleChange(e)}
								/>
								<div className="errorMsg">{props.errors["apiendpoints"]}</div>
							</div>
						</div>
						<div className="col-sm-6 col-lg-4">
							<div className="el">
								<label>API Key *</label>
								<input className="form-control" type="text"
									name="apikeys" value={apikeys}
									onChange={(e) => props.onDataInputFieldHnadleChange(e)}
								/>
								<div className="errorMsg">{props.errors["apikeys"]}</div>
							</div>
						</div>
						<div className="col-sm-6 col-lg-4">
							<div className="el">
								<label>API Token *</label>
								<input className="form-control" type=""
									name="apitokens" value={apitokens}
									onChange={(e) => props.onDataInputFieldHnadleChange(e)}
								/>
								<div className="errorMsg">{props.errors["apitokens"]}</div>
							</div>
						</div>
						<div className="col-sm-6 col-lg-4">
							<div className="el">
								<label>Other Information </label>
								<input className="form-control" type="text"
									name="otherinformations" value={otherinformations}
									onChange={(e) => props.onDataInputFieldHnadleChange(e)}
								/>
								{/* <div className="errorMsg">{props.errors["otherinformations"]}</div> */}
							</div>
						</div>
						<div className="col-sm-6 col-lg-4">
							<div className="el">							
								<label>{crmname == "7" ? "Account Id" : "Account Id *"} </label>
								<input className="form-control" type="number"
									name="accountid" value={(accountid>0)?accountid:""}
									onChange={(e) => props.onDataInputFieldHnadleChange(e)}
								/>
								{crmname!= "7" &&<div className="errorMsg">{props.errors["accountid"]}</div>}
							</div>
						</div>

						<div className="col-sm-6 col-lg-4">
							<div className="el">
								<label>Status</label>
								<select className="form-control"
									name="status" value={status}
									onChange={(e) => props.onDataInputFieldHnadleChange(e)}
								>
									<option value="1" key="Active">Active</option>
									<option value="0" key="Deactive">Deactive</option>
								</select>
								{/* <div className="errorMsg">{props.errors["agentid"]}</div>  */}
							</div>
						</div>
						<div className="col-sm-6 col-lg-4">
							<div className="el">
								<label>Is schedule</label>
								<select className="form-control" type="text"
									name="is_schedule" value={is_schedule}
									onChange={(e) => props.onDataInputFieldHnadleChange(e)}
								>
									<option value="1" key="Yes">Yes</option>
									<option value="0" key="No">No</option>
								</select>
								<div className="errorMsg">{props.errors["is_schedule"]}</div>
							</div>
						</div>
						{is_schedule === "1" && (<>
							<div className="col-sm-6 col-lg-4">
								<div className="el">
									<label>Schedule start time *</label>
									<div className="date-time">
									<Datetime
										input={true}
										dateFormat={false}
										viewMode="time"
										timeFormat="HH:mm"
										//name="schedule_start_time"
										//value={moment(schedule_start_time).format(" h:mm") }
										value={schedule_start_time}
										onChange={(e) => props.onDataInputFieldHnadleChangeshedule(e)}
										timeConstraints={{
										hours: { min: 0, max: 23 },
										minutes: { min: 0, max: 59 }
										
										}}
									/>
									</div>
									{/* <input className="form-control" type="time" 
                                    name="schedule_start_time" value={schedule_start_time}
									pattern="([01]?[0-9]|2[0-3]):[0-5][0-9]"
                                    onChange={(e) => props.onDataInputFieldHnadleChange(e)}
                                    /> */}
									<div className="errorMsg">{props.errors["schedule_start_time"]}</div>
								</div>
							</div>

							<div className="col-sm-6 col-lg-4">
								<div className="el">
									<label>Schedule Interval *</label>
									<input className="form-control" type="text"
										name="schedule_interval" value={schedule_interval}
										onChange={(e) => props.onDataInputFieldHnadleChange(e)}
									/>
									<div className="errorMsg">{props.errors["schedule_interval"]}</div>
								</div>
							</div>

						</>)}
					</div>
				</div>
			</div>
			<div className="down_pan">
				<div className="text-right">
					<Link to="/admin/listthirdparty"><button className="btn_cf dim"><i className="fa fa-times" aria-hidden="true">&nbsp;</i> Cancel</button></Link> &nbsp;
					<button className="btn_cf scss" onClick={() => props.onAddRealEstate()}>
						<i className="fal fa-save"></i>&nbsp;{props.thirdPartyById.id ? "Update" : "Save"} </button>
				</div>
			</div>

		</>
	)

}
export default AddThirdParty;