export const stateList =[
    {
        "name":"New South Wales",
        "state_code":"NSW"
    },
    {
        "name":"Victoria",
        "state_code":"VIC"
    },
    {
        "name":"Queensland",
        "state_code":"QLD"
    },
    {
        "name":"Tasmania",
        "state_code":"TAS"
    },
    {
        "name":"South Australia",
        "state_code":"SA"
    },
    {
        "name":"Western Australia",
        "state_code":"WA"
    },
    {
        "name":"Northern Territory",
        "state_code":"NT"
    },
    {
        "name":"Australian Capital Territory",
        "state_code":"ACT"
    }
]