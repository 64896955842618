import React from 'react';
import { Button } from "react-bootstrap";
//import logo from './../../img/Final_logo_100px.png';
import Loading from '../common/Loading';
//import logo from '../../../public/img/Final_logo_100px.png';
const LoginForm = (props) => {
    const { email, password } = props.login;
    return (
        // <section style={{ backgroundColor: '#172902' }}>
        //     <div classNameName="container">
        //         <div classNameName="row justify-content-center align-items-center" style={{ height: '100vh' }} >

        //             <div classNameName="col-xl-10 col-lg-12 col-md-9">

        //                 <div classNameName="card o-hidden border-0 shadow-lg my-5">
        //                     <div classNameName="card-body p-0">
        //                         <div classNameName="row">
        //                             <div classNameName="col-lg-6 d-none d-lg-block bg-login-image"></div>
        //                             <div classNameName="col-lg-6">
        //                                 <div classNameName="py-5 px-4">
        //                                     <div classNameName="text-center">
        //                                     {/* <img src={logo} classNameName="img-fluid" alt="" /> */}
        //                                         <h1 classNameName="h5 text-gray-900 mb-3 text-left mt-4">Please Enter your Details!</h1>
        //                                     </div>
        //                                     <div classNameName="form-group">
        //                                         <input type="email"
        //                                             classNameName="form-control form-control-user"
        //                                             name="email" id="email" aria-describedby="emailHelp"
        //                                             placeholder="Enter Email Address..."
        //                                             value={email} onChange={props.handleChange} />
        //                                         <div classNameName="errorMsg">{props.errors["email"]}</div>
        //                                     </div>

        //                                     <div classNameName="form-group">
        //                                         <input type="password"
        //                                             classNameName="form-control form-control-user"
        //                                             name='password' id="passwword"
        //                                             placeholder="Password"
        //                                             value={password}
        //                                             onChange={props.handleChange} />
        //                                         <div classNameName="errorMsg">{props.errors["password"]}</div>
        //                                     </div>
        //                                     <div classNameName="form-group">
        //                                         <div classNameName="custom-control custom-checkbox small">
        //                                             <input type="checkbox"
        //                                                 classNameName="custom-control-input"
        //                                                 id="customCheck" />
        //                                             <label classNameName="custom-control-label"
        //                                                 htmlFor="customCheck">Remember Me</label>
        //                                         </div>
        //                                     </div>
        //                                     <Button type="submit"
        //                                         classNameName="btn btn-warning btn-user btn-block"
        //                                         onClick={(e) => {
        //                                             e.preventDefault();
        //                                             props.checkLogin(e);
        //                                         }}
        //                                     > 
        //                                     {props.isLoding&& <Loading/>}
        //                                     Login</Button>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </section>
        <div className="dashboard-main-wrapper whbg">
		<div className="dashboard-header">
			<nav className="navbar navbar-expand-lg bg-white fixed-top">
				<div className="container">
				<a className="navbar-brand" ><img src="../../assets/images/logo.png" alt="" /></a>
				</div>
			</nav>
        </div>

        
		<div className="container  login_pg">
			<div className="d-md-flex align-items-center">
				<div className="hextxblk">
					<h2 className="login_htx mb-3">Ready to expand your business?</h2>
					<h5>Easily collaborate with your team with shared Connectors.</h5>
				</div>
				<div className="login_bx">
					<h4 className="text-center mb-3">Log in to your account</h4>
					<div className="lgncon">
						<h5 className="text-center mb-3">Welcome back!</h5>
						<div className="elpan">
							<div className="lebel">User Name</div>
							<div className="el mb-3"><input className="form-control"
                             type="text" placeholder="Enter your user name or email"
                             value={email} onChange={props.handleChange} name="email"
                             />
                               <div classNameName="errorMsg">{props.errors["email"]}</div>
                             </div>
							<div className="lebel">Password</div>
							<div className="el mb-2"><input className="form-control" type="password"
                            name='password'
                             value={password}
                             onChange={props.handleChange} 
                            placeholder="Enter your password"/>
                            <div classNameName="errorMsg">{props.errors["password"]}</div>
                            </div>
							<div className="ellnk text-center mb-2">
                                {/* <a href="#">Forgot Password</a> */}
                                </div>
							<div className="el_rad"> 
                            {/* <input id="remberme" type="checkbox"/>  */}
                            {/* <label for="remberme"></label> Remember Me */}
                            </div>
							<div className="btn_pan mt-3">
                                {/* <input className="btn_cf01" type="submit" value="Login"/> */}

                                <Button type="submit"
                                                className="btn_cf01"
                                                 onClick={(e) => {
                                                     e.preventDefault();
                                                     props.checkLogin(e);
                                                 }}
                                            > 
                                             {props.isLoding&& <Loading/>}
                                            Login</Button>
                                </div>
						</div>
					</div>
				</div>
				
			</div>
		</div>
        
    </div>
	
        );
};

export default LoginForm;