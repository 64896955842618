import React, { Fragment } from "react";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ component: Component, props, ...rest }) => {
  let isLoggedIn = (props) => {
    let pathName = props.location.pathname.toLowerCase(); 
   if (localStorage.getItem("token") !== null) {
        return <Component {...props} />;
    } else {
      return (
        <Fragment>
          <Redirect
            to={{
              pathname: "/admin/login",
              state: { from: props.location },
            }}
          />
        </Fragment>
      );
    }
  };

  return (
    <Fragment>
      <Route {...rest} render={(props) => isLoggedIn(props)} />
    </Fragment>
  );
};

export default PrivateRoute;