import React from "react";
import { Route } from "react-router-dom";
import Login from "../module/Login";

const LoginLayout = (props) => {
   
    let recivePathName=props.location?.pathname;
    
    return (
        <>
            <Route 
              path={recivePathName}
               exact 
               render={(matchProps) => (
                <Login {...matchProps} />
               )}>
            </Route>
        </>
    )
}

export default LoginLayout;