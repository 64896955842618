import {
  PENDING, ERROR,
  FETCH_THIRD_ESTATE_SUCCESS,
  FETCH_REAL_ESTATE_SUCCESS,
  UPDATE_FETCH_REAL_ESTATE_SUCCESS,
  FETCH_AGENTS_DROP_DAWN_SUCCESS,
  UPDATE_THIRD_PARTY_GET_SUCCESS,
  FETCH_INTERNAL_LIST_SUCCESS,
  UPDATE_INTERNAL_GET_SUCCESS,
  FETCH_CRM_INTERNAL_DROP_DAWN_SUCCESS,
  FETCH_CRM_EXTERNAL_DROP_DAWN_SUCCESS,
  FETCH_LOG_HISTORY_SUCCESS,
  FETCH_LOG_HISTORY_DETAILS_SUCCESS,
  FETCH_CONNECTOR_DROP_DAWN_SUCCESS,
  FETCH_CONSUMER_DROP_DAWN_SUCCESS,
  FETCH_LOG_HISTORY_DETAILS_CONSUMER_SUCCESS,
  FETCH_LOG_DETAILS_CONSUMER_SUCCESS
  
} from "./types/index";
import { toast } from "react-toastify";
import { userLogin, userLogout } from "../data/user";
//import {ExceptionHandler} from "../Utility/ExceptionHandler";

import {
  addRealEstate,
  fetchRealAgentsList,
  fetchByidRealAgentsList,
  updateRealEstate,
  fetchRealAgentsList1,
   addThirdParty,
   fetchThirdPartyList1,
  fetchRealAgentsOption,
  fetchThirdPartyById,
  updateThirdParty,
  addInternal,
  fetchInternalList,
  fetchInternalId,
  updateInternal,
  fetchCrmExternalOption,
  fetchCrmInternalOption,
  deleteRealAgents,
  deleteThridParty,
  fetchInternalList1,
  deleteInternal,
  fetchHistoryLog,
  fetchHistoryDetailsLog,
  fetchHistoryDetailsLogConsumer,
  fetchConnectorDropDawn,
  addDirectConsumer,
  fetchConsumerDropDawn,
  fetchDetailsCon
} from "./../data/RealEstate";

export function FetchRealAgentsList(params) {
  return async (dispatch) => {
    dispatch({
      type: PENDING,
    });

    try {
      const speciesList = await fetchRealAgentsList1(params).then(
        (res) => res
      );
      dispatch({
        type: FETCH_REAL_ESTATE_SUCCESS,
        payload: speciesList.data,
      });
    } catch (err) {
      dispatch({
        type: ERROR,
        error: err.response
      });
    }
  };
}


export function AddRealAgentData(params) {
  //debugger;
  return async (dispatch) => {
    dispatch({
      type: PENDING,
    });

    try {
     // toast.success("Agents added successfully");
      await addRealEstate(params).then((res) =>  toast.success(res.data?.message));
      // if (obj && obj.data) {
      //   callBack(obj && obj.data.data);
      // }

    } catch (err) {
      dispatch({
        type: ERROR,
        error: err.response
      });
    }
  };
}


export function FetchRealAgentById(params) {
  //console.log("Action ", params);
  return async (dispatch) => {
    dispatch({
      type: PENDING,
    });

    try {
      const speciesList = await fetchByidRealAgentsList(params).then(
        (res) => res
      );
      dispatch({
        type: UPDATE_FETCH_REAL_ESTATE_SUCCESS,
        payload: speciesList.data,
      });
    } catch (err) {
      dispatch({
        type: ERROR,
        error: err.response
      });
    }
  };
}

export function DeleteAgents(params,getParams) {
    return async (dispatch) => {
      dispatch({
        type: PENDING,
      });
      try {
       // toast.success("Species delete successfully");
        await deleteRealAgents(params).then((res) => toast.success(res.data?.message), 
         dispatch(FetchRealAgentsList(getParams))
        );
        //toast.success("Agents deleted successfully");
  
      } catch (err) {
        dispatch({
          type: ERROR,
          error: err.response,
        });
         if (err.response && err.response.status <= 500) {
          toast.warn(err.response.data.status.message);
        }
      }
    };
  }


  export function DeleteThridParty(params,getParams) {
    return async (dispatch) => {
      dispatch({
        type: PENDING,
      });
      try {
       // toast.success("Species delete successfully"deleteInternal);
        await deleteThridParty(params).then((res) => toast.success(res.data?.message),
         dispatch(FetchThirdList(getParams)),
        );
       // toast.success("Thrid party CRM deleted successfully");
  
      } catch (err) {
        dispatch({
          type: ERROR,
          error: err.response,
        });
         if (err.response && err.response.status <= 500) {
          toast.warn(err.response.data.status.message);
        }
      }
    };
  }

export function UpdateRealAgentData(params) {
  //debugger;
  return async (dispatch) => {
    dispatch({
      type: PENDING,
    });

    try {
     // toast.success("Agents Updated successfully");
      await updateRealEstate(params).then((res) =>  toast.success(res.data?.message));
      // if (obj && obj.data) {
      //   callBack(obj && obj.data.data);
      // }

    } catch (err) {
      dispatch({
        type: ERROR,
        error: err.response
      });
    }
  };
}


export function FetchThirdList(params) {
  return async (dispatch) => {
    dispatch({
      type: PENDING,
    });

    try {
      const speciesList = await fetchThirdPartyList1(params).then(
        (res) => res
      );
      dispatch({
        type: FETCH_THIRD_ESTATE_SUCCESS,
        payload: speciesList.data,
      });
    } catch (err) {
      dispatch({
        type: ERROR,
        error: err.response
      });
    }
  };
}


export function AddThirdPartyData(params) {
  //debugger;
  return async (dispatch) => {
    dispatch({
      type: PENDING,
    });

    try {
      await addThirdParty(params).then((res) =>  toast.success(res.data?.message));
    } catch (err) {
      toast.warn(err.response.data?.message)
      dispatch({
        type: ERROR,
        error: err.response,    
      }); 
    }
  };
}


export function FetchAgentsOption(params) {
  return async (dispatch) => {
    dispatch({
      type: PENDING,
    });

    try {
      const speciesList = await fetchRealAgentsOption(params).then(
        (res) => res
      );
      dispatch({
        type: FETCH_AGENTS_DROP_DAWN_SUCCESS,
        payload: speciesList.data,
      });
    } catch (err) {
      dispatch({
        type: ERROR,
        error: err.response
      });
    }
  };
}

export function FetchThirdPartyById(params) {
  //console.log("Action ", params);
  return async (dispatch) => {
    dispatch({
      type: PENDING,
    });

    try {
      const speciesList = await fetchThirdPartyById(params).then(
        (res) => res
      );
      dispatch({
        type: UPDATE_THIRD_PARTY_GET_SUCCESS,
        payload: speciesList.data,
      });
    } catch (err) {
      dispatch({
        type: ERROR,
        error: err.response
      });
    }
  };
}

export function UpdateThirdPartyData(params) {
  //debugger;
  return async (dispatch) => {
    dispatch({
      type: PENDING,
    });
    try {
      await updateThirdParty(params).then((res) =>  toast.success(res.data?.message));
    } 
    
    catch (err) {
     // console.log("this is log for update ",err.response.data);
      toast.warn(err.response.data?.message)
      dispatch({
        type: ERROR,
        error: err.response
      });
    }
  };
}







//INTERNAL STARTED 


export function FetchInternalList(params) {
  return async (dispatch) => {
    dispatch({
      type: PENDING,
    });

    try {
      const speciesList = await fetchInternalList1(params).then(
        (res) => res
      );
      dispatch({
        type: FETCH_INTERNAL_LIST_SUCCESS,
        payload: speciesList.data,
      });
    } catch (err) {
      dispatch({
        type: ERROR,
        error: err.response
      });
    }
  };
}


export function AddInternalData(params) {
  //debugger;
  return async (dispatch) => {
    dispatch({
      type: PENDING,
    });

    try {
      await addInternal(params).then((res) =>  toast.success(res.data?.message));
    } catch (err) {
      toast.warn(err.response.data?.message)
      dispatch({
        type: ERROR,
        error: err.response
      });
    }
  };
}

export function AddDirectConsumer(params) {
  //debugger;
  return async (dispatch) => {
    dispatch({
      type: PENDING,
    });

    try {
      await addDirectConsumer(params).then((res) =>  toast.success(res.data?.message));
    } catch (err) {
      toast.warn(err.response.data?.message)
      dispatch({
        type: ERROR,
        error: err.response
      });
    }
  };
}

export function FetchInternalById(params) {
  //console.log("Action ", params);
  return async (dispatch) => {
    dispatch({
      type: PENDING,
    });

    try {
      const speciesList = await fetchInternalId(params).then(
        (res) => res
      );
      dispatch({
        type: UPDATE_INTERNAL_GET_SUCCESS,
        payload: speciesList.data,
      });
    } catch (err) {
      dispatch({
        type: ERROR,
        error: err.response
      });
    }
  };
}

export function UpdateInternalData(params) {
  //debugger;
  return async (dispatch) => {
    dispatch({
      type: PENDING,
    });

    try {
      await updateInternal(params).then((res) =>  toast.success(res.data?.message));
      
    } catch (err) {
      toast.warn(err.response.data?.message)
      dispatch({
        type: ERROR,
        error: err.response
      });
    }
  };
}


export function DeleteInternal(params,getParams) {
  return async (dispatch) => {
    dispatch({
      type: PENDING,
    });
    try {
     // toast.success("Species delete successfully"deleteInternal);
      await deleteInternal(params).then((res) => toast.success(res.data?.message),
       dispatch(FetchInternalList(getParams))
      );
    //  toast.success("Internal CRM deleted successfully");

    } catch (err) {
      dispatch({
        type: ERROR,
        error: err.response,
      });
       if (err.response && err.response.status <= 500) {
        toast.warn(err.response.data.status.message);
      }
    }
  };
}

//CRM Drop dawn

export function FetchCrmExternalOption(params) {
  return async (dispatch) => {
    dispatch({
      type: PENDING,
    });

    try {
      const speciesList = await fetchCrmExternalOption(params).then(
        (res) => res
      );
      dispatch({
        type: FETCH_CRM_EXTERNAL_DROP_DAWN_SUCCESS,
        payload: speciesList.data,
      });
    } catch (err) {
      dispatch({
        type: ERROR,
        error: err.response
      });
    }
  };
}

export function FetchCrmInternalOption(params) {
  return async (dispatch) => {
    dispatch({
      type: PENDING,
    });

    try {
      const speciesList = await fetchCrmInternalOption(params).then(
        (res) => res
      );
      dispatch({
        type: FETCH_CRM_INTERNAL_DROP_DAWN_SUCCESS,
        payload: speciesList.data,
      });
    } catch (err) {
      dispatch({
        type: ERROR,
        error: err.response
      });
    }
  };
}


export function FetchHistory(params) {
  return async (dispatch) => {
    dispatch({
      type: PENDING,
    });

    try {
      const historyLogList = await fetchHistoryLog(params).then(
        (res) => res
      );
      dispatch({
        type: FETCH_LOG_HISTORY_SUCCESS,
        payload: historyLogList.data,
      });
    } catch (err) {
      dispatch({
        type: ERROR,
        error: err.response
      });
    }
  };
}


export function FetchHistoryDetails(params) {
  return async (dispatch) => {
    dispatch({
      type: PENDING,
    });

    try {
      const historyLogDeatils = await fetchHistoryDetailsLog(params).then(
        (res) => res
      );
      dispatch({
        type: FETCH_LOG_HISTORY_DETAILS_SUCCESS,
        payload: historyLogDeatils.data,
      });
    } catch (err) {
      dispatch({
        type: ERROR,
        error: err.response
      });
    }
  };
}

export function FetchHistoryDetailsConsumer(params) {
  return async (dispatch) => {
    dispatch({
      type: PENDING,
    });

    try {
      const historyLogDeatils = await fetchHistoryDetailsLogConsumer(params).then(
        (res) => res
      );
      dispatch({
        type: FETCH_LOG_HISTORY_DETAILS_CONSUMER_SUCCESS,
        payload: historyLogDeatils.data,
      });
    } catch (err) {
      dispatch({
        type: ERROR,
        error: err.response
      });
    }
  };
}

export function FetchConnectorDropDawn(params) {
  return async (dispatch) => {
    dispatch({
      type: PENDING,
    });

    try {
      const connectorDropdown = await fetchConnectorDropDawn(params).then(
        (res) => res
      );
      dispatch({
        type: FETCH_CONNECTOR_DROP_DAWN_SUCCESS,
        payload: connectorDropdown.data,
      });
    } catch (err) {
      dispatch({
        type: ERROR,
        error: err.response
      });
    }
  };
}

export function FetchDetailsConsumer(params) {
  return async (dispatch) => {
    dispatch({
      type: PENDING,
    });

    try {
      const historyLogDeatils = await fetchDetailsCon(params).then(
        (res) => res
      );
      dispatch({
        type: FETCH_LOG_DETAILS_CONSUMER_SUCCESS,
        payload: historyLogDeatils.data,
      });
    } catch (err) {
      dispatch({
        type: ERROR,
        error: err.response
      });
    }
  };
}


export function FetchConsumerDropDawn(params) {
  return async (dispatch) => {
    dispatch({
      type: PENDING,
    });

    try {
      const connectorDropdown = await fetchConsumerDropDawn(params).then(
        (res) => res
      );
      dispatch({
        type: FETCH_CONSUMER_DROP_DAWN_SUCCESS,
        payload: connectorDropdown.data,
      });
    } catch (err) {
      dispatch({
        type: ERROR,
        error: err.response
      });
    }
  };
}