import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  AddInternalData, FetchInternalList,
  FetchInternalById, UpdateInternalData, FetchAgentsOption,
  FetchCrmInternalOption,
  DeleteInternal
} from "../../../../actions/RealEstateAction"
import { propTypes } from "react-bootstrap/esm/Image";
import ListInternalCrm from "./ListInternalCrm";
import AddInternal from "./AddInternal";
import validateInternalCrmForm from "../../../validation_rules/InternalCrmFormRule";
import Pagination from "../../../common/Pagination";
import PopupModal from "../../../common/PopupModal";
import Loading from "../../../common/Loading";

class ManageInternalCrm extends Component {
  constructor() {
    super();
  }
  initState = {
    agentid: 0,
    crmname: "",
    environment: "1",
     baseurl: "",
    authtokendpoint: "",
    clientid: "",
    clientsecretkey: "",
    otherinformation: "",
    updateStatusTime:"",
    status:1
  }

  state = {
  agentState: this.initState,
  mode: "List",
  errors: {},
  pageNumber:1,
  pageSize:10,
  totalElements: 20,
  searchText: "",
  popupModalType: 0,
  popupTitle: "",
  deleteId:"",
  totallPage: "",
  getEndPoint:{},
  authTokenData:{}
  }

  onDataInputFieldHnadleChange = (e) => {
    const { errors } = this.state;
    delete errors[e.target.name];
    this.setState({
      ...this.state,
      agentState: {
        ...this.state.agentState,
        [e.target.name]: e.target.value

      }
    })
  }


  onCrmFieldHnadleChange = (e) => {
    const { errors } = this.state;
    delete errors[e.target.name];
    if(e.target.value ==="0"){
      this.setState({
        ...this.state,
        agentState: {
          ...this.state.agentState,
          [e.target.name]: e.target.value,
          baseurl:"",
          authtokendpoint:"",
        }
      })
    }else{
      let reciveData =   this.props.crmInternalDropDawn &&  this.props.crmInternalDropDawn.find((item)=>item.id === +e.target.value);
        this.setState({
          ...this.state,
          agentState: {
            ...this.state.agentState,
            [e.target.name]: e.target.value,
            baseurl:reciveData.endPointUrl,
            authtokendpoint:reciveData.authEndPointToken 
          }
        })
    }
  }

  onAddClick = () => {
    //this.props.history.push('../admin/addrealestate')
    this.setState({
      ...this.state,
      mode: "Add"
    }, () => {  })
  }
  onAddRealEstate = () => {
    const { agentid,
      crmname,
      environment,
       baseurl,
      authtokendpoint,
      clientid,
      clientsecretkey,
      otherinformation,updateStatusTime } = this.state.agentState;
    let errors = validateInternalCrmForm({
      agentid,
      crmname,
      environment,
       baseurl,
      authtokendpoint,
      clientid,
      clientsecretkey,
      otherinformation,
      updateStatusTime
    });

    if (Object.entries(errors).length > 0) {
      this.setState({
        ...this.state,
        errors: errors
      })
    } else {
      const params = {
        data: this.state.agentState
      }
      const upDateParams={
        data:this.state.agentState,
        id:this.props.internalById.id
       }
       if(this.props.internalById.id){
        this.props.UpdateInternalData(upDateParams)
        this.props.history.push("/admin/listinternalcrm")
      }else{
      this.props.AddInternalData(params);
      this.props.history.push("/admin/listinternalcrm")
      }
    }
  }
  searchHandleChange = (e) => {
    this.setState({ ...this.state, searchText: e.target.value }
      ,()=>{});
  };
  onSearchFormdata =async (pageNumber, pageSize) => {
    this.setState({
      ...this.state,
      pageNumber: pageNumber,
      pageSize: pageSize,
      //goToPageNumber: pageNumber,
     
    });
    const params = {
      page: pageNumber,
      size: pageSize,
      searchText: this.state.searchText,
     
    };
    // const cbLoading = () => {
    //   this.setState({
    //     isLoading: "DONE",
    //   });
    // };
   await this.props.FetchInternalList(params);
    const { count, size } = this.props.paginationData2;
    let totalPage = Math.ceil(+count / +size)
    this.setState({
      totallPage: totalPage,
    });
  };


  onEditClick = async (reciveId) => {
    //console.log("consle.....");
    const params = {
      data: reciveId
    }
    await this.props.FetchInternalById(params)
    // this.setState({
    //   ...this.state,
    //   agentState: this.props.thirdPartyById
    // })
  }
  clearSelection = () => {
    this.setState({
      popupModalType: 0,
      popupTitle: "",
    });
  };
  
  openDeleteModal = (id, type) => {
    //console.log("type...........",type,id);
    this.setState({
      deleteId: id,
      popupModalType: type,
      popupTitle:
      type === 1
          ? "you want to delete this!"
          : type === 2
          ? "you want to deactivate this!"
          : "you want to delete this!",
    });
  };
  onDelete = () => {
    const params = {
      data: {
        sp_id: this.state.deleteId
      }
  }
    if (this.state.popupModalType > 0 && this.state.popupModalType === 1) {
      this.props.DeleteInternal(params,{});
      this.clearSelection();
    } else if (
      this.state.popupModalType > 0 &&
      this.state.popupModalType === 2
    ) {
      this.props.StatusChange(params);
      this.clearSelection();
    }else {
        this.props.DeleteInternal(params,{});
        this.clearSelection();
  
    }
  };
  onPopupCancel = (e) => {
    this.setState({
      ...this.state,
      popupModalType: 0,
      popupTitle: "",
    });
  };

  async componentDidMount() {
    let id=this.props.match.params?.id
    await this.props.FetchInternalList();
    await this.props.FetchAgentsOption();
    await this.props.FetchCrmInternalOption();
    this.findEndPoint();
    this.onSearchFormdata(this.state.pageNumber, this.state.pageSize)
    const params = {
      data:id
    }
     this.props.FetchInternalById(params)

  }
findEndPoint =()=>{
  let recivedata= this.props.crmInternalDropDawn &&  this.props.crmInternalDropDawn.find(item=> item.endPointUrl);
  let recivedataAuth= this.props.crmInternalDropDawn &&  this.props.crmInternalDropDawn.find(item=> item.authEndPointToken); 
  this.setState({
    ...this.state,
      getEndPoint:recivedata,
      authTokenData:recivedataAuth
  })
}

  getDataOnEditMode =async () => {
    const params = {
      data: this.props.internalById?.id
    }
    await this.props.FetchInternalById(params)
    this.setState({
      ...this.state,
      agentState: {
        ...this.state.agentState,
        agentid: this.props.internalById?.agentId,
        crmname: this.props.internalById?.crmId,
        environment: this.props.internalById?.environment,
        baseurl: this.props.internalById?.baseUrlLink,
        authtokendpoint: this.props.internalById?.authtokendPoint,
        clientid: this.props.internalById?.clientId,
        clientsecretkey: this.props.internalById?.clientSecretKey,
        otherinformation: this.props.internalById?.otherInformation,
        updateStatusTime: this.props.internalById?.updateStatusTime,
        status:this.props.internalById?.status,

      }
    })
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.internalById.id !== prevProps.internalById.id) {

      this.getDataOnEditMode();
    }
    if (this.state.searchText !== prevState.searchText) {
      if (this.state.searchText.length > 2) {
        this.onSearchFormdata(1, this.state.pageSize);
      } else if (this.state.searchText.length === 0) {
        this.onSearchFormdata(1, this.state.pageSize);
      }
    }

  }
  render() {
    const { count, size } = this.props?.paginationData2;
    return (
      <React.Fragment>
        {this.props.location.pathname === "/admin/listinternalcrm/addinternalcrm" &&
          <AddInternal
            agentState={this.state.agentState}
            onDataInputFieldHnadleChange={this.onDataInputFieldHnadleChange}
            onCrmFieldHnadleChange={this.onCrmFieldHnadleChange}
            onAddRealEstate={this.onAddRealEstate}
            errors={this.state.errors}
            internalById={this.props.internalById}
            agentsDropDawn={this.props.agentsDropDawn}
            crmInternalDropDawn={this.props.crmInternalDropDawn}
          />
        }

        {this.props.location.pathname.includes("/admin/listinternalcrm/addinternalcrm/edit") &&
          <AddInternal
            agentState={this.state.agentState}
            onDataInputFieldHnadleChange={this.onDataInputFieldHnadleChange}
            onCrmFieldHnadleChange={this.onCrmFieldHnadleChange}
            onAddRealEstate={this.onAddRealEstate}
            errors={this.state.errors}
            internalById={this.props.internalById}
            agentsDropDawn={this.props.agentsDropDawn}
            crmInternalDropDawn={this.props.crmInternalDropDawn}
          />
        }
        {this.props.location.pathname === "/admin/listinternalcrm" && ( <>
          {this.props.pending && <Loading/>}

          <ListInternalCrm
          internalList={this.props.internalList}
            onAddClick={this.onAddClick}
            onEditClick={this.onEditClick}
            searchText={this.state.searchText}
            openDeleteModal={this.openDeleteModal}
            searchHandleChange={this.searchHandleChange}
          />

          <PopupModal
          popupId="detele-popup"
          onPopup={this.onDelete}
          type={this.state.popupModalType ? this.state.popupModalType : ""}
          popupText={this.state.popupTitle}
          onCancel={this.onPopupCancel}
        />
       <Pagination
       // pageNumber={this.props.pageNumber}
       // pageSize={props.pageSize}
       // totalElements={totalElements}
       // totalPages={totalPages}
       updateView={this.onSearchFormdata}
              pageNumber={this.state.pageNumber}
              pageSize={size}
              totalElements={count}
              totalPages={this.state.totallPage}
       // value={props.goToPageNumber}
        handlePageChangeValue={this.props.handlePageChangeValue}
       // goToPage={props.goToPage}
     />
     </>)
        }
      </React.Fragment>
    );
  }
}

//export default ManageSpecies;

const mapStateToProps = (state) => {
  let {
    AgentsReducer: {
      pending,
      error,
      internalList,
      internalById,
      agentsDropDawn,
      crmInternalDropDawn,
      paginationData2
    }
  } = state;

  return {
    pending: pending,
    error: error,
    internalList: internalList,
    internalById: internalById,
    agentsDropDawn:agentsDropDawn,
    crmInternalDropDawn:crmInternalDropDawn,
    paginationData2: paginationData2
  };
};

ManageInternalCrm.propTypes = {
  AddInternalData: PropTypes.func,
  FetchInternalList: PropTypes.func,
  FetchAgentsOption: PropTypes.func,
  FetchCrmInternalOption:PropTypes.func,
  FetchRealAgentById: PropTypes.func,
  FetchInternalById:PropTypes.func,
  UpdateInternalData:PropTypes.func,
  UpdateRealAgentData: PropTypes.func,
  DeleteInternal:PropTypes.func,
  //history: PropTypes.object,
  pending: PropTypes.bool,
  error: PropTypes.object,
};
export default connect(mapStateToProps, {
  AddInternalData,
  FetchInternalList,
  FetchAgentsOption,
  FetchCrmInternalOption,
  FetchInternalById, 
  UpdateInternalData,
  DeleteInternal
})(ManageInternalCrm);
