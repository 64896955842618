import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ListConsumer from "./ListConsumer";
import AddConsumer from "./AddConsumer"; 


class ManageConsumer extends Component {
  
  state = {
    mode: "List",
    errors: {},
  }

  render() {
  
    return (
      <React.Fragment>
        {this.props.location.pathname === "/admin/listconsumer/addconsumer" &&
          <AddConsumer />
        }
        {this.props.location.pathname.includes("admin/listconsumer/addconsumer/edit") &&
          <AddConsumer/>
        }
        {this.props.location.pathname === "/admin/listconsumer" &&
          <ListConsumer/>
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  let {
    AgentsReducer: {
      pending,
      error,
    }
  } = state;

  return {
    pending: pending,
    error: error,
  };
};

ManageConsumer.propTypes = {
  pending: PropTypes.bool,
  error: PropTypes.object,
};
export default connect(mapStateToProps, {
})(ManageConsumer);
