import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import {
  FetchHistoryDetailsConsumer,FetchDetailsConsumer,FetchConsumerDropDawn
} from "../../../../actions/RealEstateAction"
import { propTypes } from "react-bootstrap/esm/Image";
import ListConsumerLog from "./ListConsumerLog";
 import LogHistory from "./ConsumerLogDetails";
 import LogConsumerDetails from "./ConsumerLogDetails"
// import validateInternalCrmForm from "../../../validation_rules/InternalCrmFormRule";
import Pagination from "../../../common/Pagination";
import PopupModal from "../../../common/PopupModal";
import Loading from "../../../common/Loading";
import { toast } from "react-toastify";
const date = new Date();
const futureDate = date.getDate();
date.setDate(futureDate);
const modifiedDate = date.toLocaleDateString('en-CA');
class ManageGlobleConnect extends Component {
  constructor() {
    super();
  }
  state = {
  mode: "List",
  errors: {},
  pageNumber:1,
  pageSize:20,
  totalElements: 50,
  searchText: "",
  popupModalType: 0,
  popupTitle: "",
  deleteId:"",
  totallPage: "",
  getEndPoint:{},
  historyDetail:{},
  connectorid:"",
  startdate:"",
  enddate:new Date()
  
  }



  searchHandleChange = (e) => {
    this.setState({ ...this.state, searchText: e.target.value }
      );};

  onSearchFormdata =async (pageNumber, pageSize) => {
    this.setState({
      ...this.state,
      pageNumber: pageNumber,
      pageSize: pageSize,
     
    });

   

    const params = {
      page: pageNumber,
      size: pageSize,
      searchText: this.state.searchText,
      connectorid:this.state.connectorid,  
      startdate:moment(this.state.startdate).format("yyyy-MM-DD"),   
      enddate:moment(this.state.enddate).format("yyyy-MM-DD"),
     
    };
    
     if(this.props?.paginationData4?.posteddata){
        const{connectorid,startdate,enddate}=this.props?.paginationData4?.posteddata
        //console.log("this is log......1234",connectorid,startdate,enddate);
        await this.props.FetchHistoryDetailsConsumer(params);
        const { count, size } = this.props.paginationData4;
        let totalPage = Math.ceil(+count / +size)
        this.setState({
          connectorid:connectorid,
          totallPage: totalPage,
          startdate:new Date(startdate),
          enddate:new Date(enddate),
        });
     
      }
    // }
   
   await this.props.FetchConsumerDropDawn(params)
    
  };


  onEditClick = async (reciveId) => {
    const params = {
      data: reciveId
    }
    await this.props.FetchDetailsConsumer(params)
    this.setState({
      ...this.state,
      historyDetail: this.props.logconsumerDetail
    })
  }

  

  async componentDidMount() {
    let id=this.props.match.params?.id
    
     this.onSearchFormdata(this.state.pageNumber, this.state.pageSize)
    const params1 = {
      data:id
    }
//this.props.FetchConnectorDropDawn()
     if(id){
      this.props.FetchDetailsConsumer(params1)
     }
   
  }


  getDataOnEditMode =async () => {
    const params = {
      data: this.props.logconsumerDetail?.id
    }
    await this.props.FetchDetailsConsumer(params)
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.logconsumerDetail.id !== prevProps.logconsumerDetail.id) {

      this.getDataOnEditMode();
    }
    if (this.state.searchText !== prevState.searchText) {
      if (this.state.searchText.length > 2) {
        this.onSearchFormdata(1, this.state.pageSize);
      } else if (this.state.searchText.length === 0) {
        this.onSearchFormdata(1, this.state.pageSize);
      }
    }

  }

  onHandleChangeAdvSerch = (e)=>{
    this.setState({
      ...this.state,
      [e.target.name]:e.target.value
    })
  }
  onHandleChangeStartDate = (date)=>{
    this.setState({
      ...this.state,
      startdate: date
    })
  }
  onHandleChangeEndDate = (date)=>{
    this.setState({
      ...this.state,
      enddate: date
    })
  }
  getHistoryList =async()=>{ 
  
    const params = {
      page: this.state.pageNumber,
      size: this.state.pageSize,
      searchText: this.state.searchText,
      connectorid:this.state.connectorid,
      startdate:moment(this.state.startdate).format("yyyy-MM-DD"),
     enddate:moment(this.state.enddate).format("yyyy-MM-DD"),
     
    };
    if(this.state.connectorid !=="" && this.state.startdate !=="" && this.state.enddate !==""){
      await this.props.FetchHistoryDetailsConsumer(params);
      const { count, size } = this.props.paginationData4;
      let totalPage = Math.ceil(+count / +size)
      this.setState({
        totallPage: totalPage,
      });
    } else{
      toast.warn("Please select direct to consumer, start date and end date")
    }
  }


  ogBackTolIST=async(e)=>{
    //window.location.href ="/admin/ListConsumerLog";
   // 
   
    const{connectorid,startdate,enddate,page}=this.props.paginationData4?.posteddata
  
    const params = {
        page: page,
        size: this.state.pageSize,
        searchText:this.state.searchText,
        connectorid:connectorid,     
        startdate:moment(startdate).format("yyyy-MM-DD"),
        enddate:moment(enddate).format("yyyy-MM-DD"),
       
      };
      const { count, size } = this.props.paginationData4;
      let totalPage = Math.ceil(+count / +size)
      this.setState({
        ...this.state,
        connectorid:connectorid,
        pageNumber: page,
        totallPage: totalPage,
        startdate:startdate,
        enddate:enddate,
       
      })
     // console.log("params log...",params);
      await this.props.FetchHistoryDetailsConsumer(params);
      await this.props.history.push("/admin/ListConsumerLog")
  
    
   
  }
  render() {
    //console.log("this is log history........ component...",this.props.logconsumerDetail);
   const { count, size } = this.props?.paginationData4;
 
    return (
      <React.Fragment>
        {this.props.location.pathname === "/admin/ListConsumerLog" && ( <>
          {this.props.pending  && <Loading/>}
          <ListConsumerLog
          logHistoryConsumer={this.props.logHistoryConsumer}
          onEditClick={this.onEditClick}
          searchHandleChange={this.searchHandleChange}
          searchText={this.state.searchText}
          consumerDropDawn={this.props.consumerDropDawn}
          connectorid={this.state.connectorid}
          startdate={this.state.startdate}
          enddate={this.state.enddate}
          onHandleChangeAdvSerch={this.onHandleChangeAdvSerch}
          onHandleChangeStartDate={this.onHandleChangeStartDate}
          onHandleChangeEndDate={this.onHandleChangeEndDate}
          getHistoryList={this.getHistoryList}
          />
 
          <PopupModal/>
          {count > 20 &&(
       <Pagination
            updateView={this.onSearchFormdata}
            pageNumber={this.state.pageNumber}
            pageSize={size}
            totalElements={count}
            totalPages={this.state.totallPage}
            handlePageChangeValue={this.props.handlePageChangeValue}
       
     />
      )} 
     </>)
        }
         {this.props.location.pathname.includes("/admin/ListConsumerLog/ConsumerLogDetails/view/") &&( <>
          { this.props.pendingHistory && <Loading/>}
         <LogConsumerDetails
         logconsumerDetail={this.props.logconsumerDetail}
         ogBackTolIST={this.ogBackTolIST}
         posteddata= {this.props.paginationData4?.posteddata}
        //  connectorid={this.state.connectorid}
        //   startdate={this.state.startdate}
        //   enddate={this.state.enddate}
        //   page= {this.state.pageNumber}
        //   size={ this.state.pageSize}
         /> </>)}
      </React.Fragment>
    );
  }
}

//export default ManageSpecies;

const mapStateToProps = (state) => {
  let {
    AgentsReducer: {
      pending,
      pendingHistory,
      error,
      logHistoryConsumer,
      logconsumerDetail,
     consumerDropDawn,
    //   agentsDropDawn,
    //   crmInternalDropDawn,
    paginationData4
    }
  } = state;

  return {
    pending: pending,
    pendingHistory:pendingHistory,
    error: error,
    logHistoryConsumer: logHistoryConsumer,
    logconsumerDetail: logconsumerDetail,
    consumerDropDawn:consumerDropDawn,
    // agentsDropDawn:agentsDropDawn,
    // crmInternalDropDawn:crmInternalDropDawn,
     paginationData4: paginationData4
  };
};

ManageGlobleConnect.propTypes = {
  FetchHistoryDetailsConsumer:PropTypes.func,
    FetchConsumerDropDawn:PropTypes.func,
    FetchDetailsConsumer:PropTypes.func,
  //history: PropTypes.object,
  pending: PropTypes.bool,
  pendingHistory:PropTypes.bool,
  error: PropTypes.object,
};
export default connect(mapStateToProps, {
  FetchHistoryDetailsConsumer,
  FetchDetailsConsumer,
    FetchConsumerDropDawn
})(ManageGlobleConnect);
