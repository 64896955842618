import React, { Component,useState,useEffect,useMemo } from 'react';
import { Link } from "react-router-dom";
import * as Api from "../../../../data/ApiRequest";
import * as path from "../../../../data/ApiList";
import { toast } from 'react-toastify';
import Loading from '../../../common/Loading';
import Pagination from "../Pagination/Pagination"
import { confirmAlert } from 'react-confirm-alert';
import "../../../../../src/react-confirm-alert.css";
let PageSize = 50;
const ListConsumer = (props) => {
    
	const [page, setPage] = useState([]);
	const[keysearch,setKeysearch] = useState([]);
	const [totalrecord, setTotalrecord] = useState([]);
	const [reciveStatus,setReciveStatus]= useState(true)
	useEffect(() =>{
		getContent();
	},[]);
	const getContent = (keyword='',numberpage=1) =>{ 
		setReciveStatus(true)
		 Api.getRequest(path.CONSUMER_LIST_URL+keyword+'&page='+numberpage+'&size='+PageSize,'').then((res)=>{
			setPage(res.data.rows);
			setTotalrecord(res.data.count)
			setReciveStatus(false)
			
		});
	   }
	   const [currentPage, setCurrentPage] = useState(1);

		const currentPosts = useMemo(() => {
			const firstPageIndex = (currentPage - 1) * PageSize;
			const lastPageIndex = firstPageIndex + PageSize;
			getContent(keysearch,currentPage);				
		}, [currentPage]);
	   function handleChange(event)
	   {
			let searchKey = event.target.value;
			setKeysearch(searchKey);
			getContent(searchKey);
	   }	
	   const deleteconnector = async(e)  =>{		
		confirmAlert({
			title: 'Are you sure ?',
			message: 'you want to delete this!',
			buttons: [
			  {
				label: 'Yes,Delete',
				onClick: () => {
					setReciveStatus(true)
			let id= e.target.getAttribute("id");
			Api.getRequest(path.CONSUMER_DELETE_URL+id,'').then((res)=>{
				if(res.data.error === false)
				{
					setReciveStatus(false)
					getContent();
					toast.success("Consumer has been deleted successfully.",{
						position: toast.POSITION.TOP_CENTER
					});					
				}				
				else
				{
					setReciveStatus(false)
					toast.error("Something went wrong.Please try again.",{
						position: toast.POSITION.TOP_CENTER
					});
				}	
			});
				}
			  },
			  {
				label: 'Cancel',
			  }
			]
		  });
		// const choice = window.confirm(
		// 	'Are you sure you want to delete everything?'
		//   );
		//   if (choice) {
		// 	setReciveStatus(true)
		// 	let id= e.target.getAttribute("id");
		// 	await Api.getRequest(path.CONSUMER_DELETE_URL+id,'').then((res)=>{
		// 		if(res.data.error === false)
		// 		{
		// 			setReciveStatus(false)
		// 			getContent();
		// 			toast.success("Consumer has been deleted successfully.",{
		// 				position: toast.POSITION.TOP_CENTER
		// 			});					
		// 		}				
		// 		else
		// 		{
		// 			setReciveStatus(false)
		// 			toast.error("Something went wrong.Please try again.",{
		// 				position: toast.POSITION.TOP_CENTER
		// 			});
		// 		}	
		// 	});
		//   }			
	   } 	   
	   const changeStatus = async(e)  =>{ 
		setReciveStatus(true)
	   let id= e.target.getAttribute("dataid");
	   let status= e.target.getAttribute("data1");
	   await Api.getRequest(path.CONSUMER_STATUS_UPDATE_URL+id+'/'+status,'').then((res)=>{
		   if(res.data.error === false)
		
		   {
			setReciveStatus(false)
			   if(status == 1)
			   {
				   //var msg = "Connector has been enabled successfully.";
				   toast.success("Connector has been enabled successfully.",{
					position: toast.POSITION.TOP_CENTER
				});	
			   }
			   else{
				setReciveStatus(false)
				   //var msg = "Connector has been disabled successfully.";
				   toast.success("Connector has been disabled successfully.",{
					position: toast.POSITION.TOP_CENTER
				});	
			   }
			   getContent();
			//    toast.success(msg,{
			// 	   position: toast.POSITION.TOP_CENTER
			//    });					
		   }				
		   else
		   {
			   toast.error("Something went wrong.Please try again.",{
				   position: toast.POSITION.TOP_CENTER
			   });
		   }	
	   });
   }

//    showLoader =()=>{
// 	this.setState({
// 	  reciveStatus:true
// 	})
  
   //}
    return (
        <>
		{reciveStatus ===true && <Loading/>} 
		    <nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item"><a href="#" className="breadcrumb-link">Dashboard</a></li>
						<li className="breadcrumb-item active" aria-current="page">Direct To Consumer</li>
					</ol>
				</nav>
				<div className="card">
					<div className="card-body">
						<div className="mb-1">
							<h5 className="hed01">Direct To Consumer</h5>
						</div>	
						<div className="d-flex align-items-center justify-content-between mb-3">
							<input className="form-control serch_hed" onChange={handleChange} type="text" placeholder="Search.."/>
							<Link to="../admin/listconsumer/addconsumer" className="btn btn-cf01 rounded"><i className="far fa-edit"></i>Add Direct To Consumer</Link>
						</div>
						{page.length >0 ? page.map((data,index) =>(							

						<div className="mb-3 bx_conect">
							<div key={index} className="d-flex align-items-center justify-content-between cntop">							
								<span className="nem margin-right-4 me-4" style={{ textTransform: 'capitalize' }}>{data.title}</span>
								<span className="nem" style={{ textTransform: 'capitalize' }}>{data.agent_details.agentName}</span>					
								<div className="smile-rating-container">
									<form className="submit-rating">
										<input id="off" onClick={changeStatus}
										 checked={(data.status===0)?"checked":''}
										  dataId ={data.id} data1={(data.status==="0")? 1 :0} 
										  name="satisfaction" type="radio" /> 
										<input checked={(data.status==="1")?"checked":''} onClick={changeStatus} dataId ={data.id} data1={(data.status===1)? 0 :1} id="on" name="satisfaction" type="radio" /> 
										<label for="off" className="rating-label rating-label-meh">Off</label>
										<div className="smile-rating-toggle"></div>
										<div className="toggle-rating-pill"></div>
										<label for="on" onClick={changeStatus}
										 className="rating-label rating-label-fun">On</label>
									</form>
								</div>
								
								<div className="dropdown d-inline d-inline-block lookdd">
									<button className="dd_show dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										<span></span>
										<span></span>
										<span></span>
									</button>
									
									<div className="dropdown-menu dropdown-menu-right">										
										<Link to={`/admin/listconsumer/addconsumer/edit/${data.id}`} title="Edit" className="dropdown-item">
										<i className="far fa-list"></i> Details</Link>										
										<Link className="dropdown-item" id={data.id} 
										onClick={deleteconnector}><i className="fal fa-trash-alt"></i> Delete</Link>
									</div>
								</div>
							</div>							
						</div>
						)):
						<div className="no-data">
							<h5>No Data Found</h5>
						</div>
						}
						<Pagination
							className="pagination-bar"
							currentPage={currentPage}
							totalCount={totalrecord}
							pageSize={PageSize}
							onPageChange={page => setCurrentPage(page)}
						/>				
						
					</div>
				</div>
        </>
    )
}

export default ListConsumer;