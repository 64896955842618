import React, { Component } from "react";
import { connect } from "react-redux";
import CMSHeader from "./CMSHeader";
import CMSHeader2 from "./CMSHeader2";
import CMSFooter from "./CMSFooter";
import * as Api from "../../../../data/ApiRequest";
import * as path from "../../../../data/ApiList";
import {CountryCodeOption} from "../Comman/CountryCodeOption"
import {stateList} from '../Comman/Statelist'
import {toast } from 'react-toastify'; 
import Loading from "../../../common/Loading";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
class CMSPage extends Component {


   intState={
    access_token:"",
    property_address:"",
    city:"",
    first_name:"",
    last_name:"",
    Email_id:"",
    country_code:"+61",
    phone:"",
    funding_amount:"",
    state:"",
    termconditonCheck:false,
    enableTermStatus:"",
  
    }
    constructor() {
        super();
        this.state = {
            toggleSidebar:false,
            firstName:"",
            lastName:"",
            reciveConsumerData:{},
             directConsumState:this.intState,
             reciveStatus:false,            
             checkTermCondition:true,
             show:false,
             reciveStatusCode:200
           
        }
    }
   
    componentDidMount=async()=>{
      document.title ="";
   const pathRecive= this.props.location?.pathname;
   
   var splitUrl = pathRecive.split('/');   
   this.setState({
    ...this.state,
    directConsumState: {
      ...this.state.directConsumState,
      access_token: splitUrl[1],    
    }
  })
  try{
    await Api.getRequest(path.GET_CMS_DETAIL_URL+splitUrl[1]).then((res1)=>{ 
      document.title = (res1.data.title != undefined)?res1.data.title:"";
      this.setState({
        reciveConsumerData:res1.data,
        reciveStatusCode:res1.status, 
        reciveStatus:true,     
      })
     
      });
  }catch(error){
    this.setState({
      reciveStatusCode:error.response.status,
      reciveStatus:false,
    })
  }

  
  }

 showLoader =()=>{
  this.setState({
    reciveStatus:true
  })

 }

    render() {
      
        return (
          
            <React.Fragment>
             
              { this.state.reciveStatusCode === 200 ? (<>{this.state.reciveStatus=== false &&<Loading/>}    
         
         
         {this.state.reciveConsumerData?.slug == 'target-market-determinations' ? 
            <CMSHeader2/> : <CMSHeader/>}
         <div className={this.state.reciveConsumerData?.slug == 'target-market-determinations' ? 'et_pb_section2':'et_pb_section'}>
            <div className="container">
            {this.state.reciveConsumerData?.slug == 'electronic-communication-consent' ? 
            <div className="d-sm-flex img-tg align-items-center justify-content-between">
            <h2 className="et_pb_slide_title ecc">{this.state.reciveConsumerData?.title}</h2>
            <img src="./img/Campaign-Flow-Icon-Logo-EXTRENDED.png" />
            </div>
             : 
            <h2 className="et_pb_slide_title">{this.state.reciveConsumerData?.title}</h2>}
            
          </div>
        </div> 
    
        <div className="et_pb_row">
            <div className="container" dangerouslySetInnerHTML={{ __html: this.state.reciveConsumerData?.content }}></div>
            </div>
            {this.state.reciveConsumerData?.slug == 'electronic-communication-consent' ? <><br/><br/><br/><br/><br/><br/><br/><br/><br/></>:""}
<CMSFooter data={this.state.reciveConsumerData.slug} /></>):(<> <div className="center-data">
            <div className="no-match container">
              <div className="error-msg">
                <h1>ERROR {this.state.reciveStatusCode}</h1>
                <h2>ACCESS FORBIDDEN</h2>
                <p>The page your are trying to access is not Authorized to you. Please contact Adminstrator.</p>
        </div>
            </div>
        </div></>)}
            
            </React.Fragment>
        );
    }
}



const mapStateToProps = (state) => {
   {}
};

CMSPage.propTypes = {
   
};

export default connect(mapStateToProps, {
   
})(CMSPage);

//export default CMSHeader;