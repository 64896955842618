import React, {
	//Component
	useState, useEffect
} from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as Api from "../../../../data/ApiRequest";
import * as path from "../../../../data/ApiList";
import {CountryCodeOption} from "../Comman/CountryCodeOption";
import {
	// ToastContainer, 
	toast
} from 'react-toastify';
import { useParams, useHistory } from 'react-router-dom';
import Editor from 'react-simple-wysiwyg';
import Loading from '../../../common/Loading';

const AddConsumer = (props) => {

	const {
		register,
		handleSubmit,		
		reset, 
		setValue,		
		formState: { errors }
	} = useForm();
	const date = new Date();
	const futureDate = date.getDate();
	date.setDate(futureDate);
	const modifiedDate = date.toLocaleDateString('en-CA');
	const [authKeyValue, setAthkey] = useState("");
	const [agentdata1, setAgentdata1] = useState([]);
	const [agent_name, setAgent_name] = useState([]);
	const [title, setTitle] = useState([]);
	const [colorcode, setcolorcode] = useState("#28a745");
	const [internalid, setInternalid] = useState("")
	const [termscondition, setTermscondition] = useState("")
	const [footercontent, setFootercontent] = useState("")
	const [pagecontent, setPagecontent] = useState("")
	const [status, setStatus] = useState("")
	const [country, setCountry] = useState("")
	const [enableTermStatus,setEnableTermStatus] = useState("0")
	const [companylogo, setCompanylogo] = useState()
	const [banner_image, setBanner_image] = useState()
	const [dropdown1, setdropdown1] = useState([]);
	const [dropdown2, setdropdown2] = useState([]);
	const [editdata, setEditdata] = useState([]);
	const [baseUrl, setBaseUrl] = useState("");
	const [errorStatusBanner ,setErrorStatusBanner] =useState(false)
	const [errorMsgBanner ,setErrorMsgBanner] =useState("")
	const [errorStatusLogo ,setErrorStatusLogo] =useState(false)
	const [errorMsgLogo ,setErrorMsgLogo] =useState("")

	const [editdate, setEditdate] = useState({ enablefromdate: modifiedDate });
	const [reciveStatus,setReciveStatus]= useState(true)
	const [disableSubmit, setDisableSubmit]=useState(false)
	const parameter = useParams();
	const history = useHistory();

 useEffect(()=>{
	if(parameter.mode === undefined ){
		//setReciveStatus(false)
	}
 })
const onClickSubmit =()=>{
	setDisableSubmit(true);
}
	useEffect(() => {
		getagentDropDown();
	}, []);
	const getagentDropDown = async () => {
		
		await Api.getRequest(path.AGENTS_DROP_DAWN, '').then((res) => {
			
			setAgentdata1(res.data);
			setReciveStatus(false)
		});
		if (parameter.id != undefined) {
			await Api.getRequest(path.CONSUMER_UPDATE_GET_URL + '/' + parameter.id, '').then((res) => {				
				setEditdata(res.data);
				setAthkey(res.data.authentication_key);
				setAgent_name(res.data.agentId);
				setInternalid(res.data.internal_crm_id);
				setTitle(res.data.title);
				setcolorcode(res.data.color_code);
				setEditdate(res.data);
				setCountry(res.data.country);
				setStatus(res.data.status);
				setTermscondition(res.data.term_and_conditions);
				setFootercontent(res.data.footer_content);
				setPagecontent(res.data.page_content);
				setCompanylogo(res.data.company_logo);
				setBanner_image(res.data.banner_image);
				setBaseUrl(res.data.base_url);
				setEnableTermStatus(res.data.enableTermStatus);
				//setValue('termscondition', res.data.term_and_condition);
				 reset({ title: res.data.title, agentid: res.data.agentid,  internalid: res.data.internal_crm_id, colorcode:res.data.color_code,country:res.data.country,status:res.data.status })
				Api.getRequest(path.CONNECTOR_GET_ALL_DROPDOWN_URL + res.data.agentId, '').then((res1) => {
					//console.log("thids is response data,....",res.data);										
					setdropdown2(res1.data.result.internalCrmData);
					setInternalid(res.data.internal_crm_id);
					setReciveStatus(false)
				});
			});
		}
	}
	async function handleChanges(event) {
		let agentid = event.target.value;
		await Api.getRequest(path.CONNECTOR_GET_ALL_DROPDOWN_URL + agentid, '').then((res) => {
			setdropdown1(res.data.result.thirdpartycrm);
			setdropdown2(res.data.result.internalCrmData);			
			reset({ internalid: '' });
		});
	}

	function handleChangeLogo(event) {
		const fileName =event.target.files[0].name
		const reciveData =fileName.split('.').pop();	
		if((reciveData === "png" || reciveData ==="jpg" || reciveData ==="jpeg")) {
			setErrorStatusLogo(false);
			setErrorMsgLogo("");
		}else if((reciveData !== "png" || reciveData !=="jpg" ||  reciveData !=="jpeg")){
			setErrorStatusLogo(true);
			setErrorMsgLogo("Only jpg,jpeg/png format accepted");
		}

	}

	function handleChangeBanner(event) {
		const fileName =event.target.files[0].name
		const reciveData =fileName.split('.').pop();	
		if((reciveData === "png" || reciveData ==="jpg" || reciveData ==="jpeg")) {
			setErrorStatusBanner(false);
			setErrorMsgBanner("");
		}else if((reciveData !== "png" || reciveData !=="jpg" ||  reciveData !=="jpeg")){
			setErrorStatusBanner(true);
			setErrorMsgBanner("Only jpg,jpeg/png format accepted");
		}
	}
function handleChangeEnable (event){
	setEnableTermStatus(event.target.value)
}

	
	//console.log("errors",errors);
	const onSubmit = data => { 
		setReciveStatus(true)
		setDisableSubmit(true);
       const fileName =data.banner_image[0]?.name
		const reciveData =fileName?.split('.').pop();
		const fileNameLogo =data.companylogo[0]?.name
		const reciveDataLogo =fileNameLogo?.split('.').pop();
		if((reciveData === "png" || reciveData ==="jpg"   || reciveData ==="jpeg"|| reciveData===undefined) && (reciveDataLogo ==="png" || reciveDataLogo ==="jpg" || reciveDataLogo ==="jpeg" ||reciveDataLogo===undefined) ) {
			const formData = new FormData();			
			formData.append("title", data.title);
			formData.append("agent_name", data.agent_name);
			formData.append("colorcode", data.colorcode);
			formData.append("internalid", data.internalid);
			formData.append("country", data.country);
			formData.append("status", data.status);
			formData.append("termscondition", data.termscondition);
			formData.append("footer_content", data.footercontent);
			formData.append("page_content", data.pagecontent);
			formData.append("enableTermStatus",data.enableTermStatus);
			formData.append("companylogo", data.companylogo[0]);
			formData.append("banner_image", data.banner_image[0]);			
			if (parameter.id != undefined) {
				setReciveStatus(true)
				Api.postRequest3(path.CONSUMER_UPDATE_URL + "/" + parameter.id, formData).then((res) => {
					//console.log("data:", res.data.error);
					if (res.data.error == false) {
					
						toast.success("Consumer has been updated successfully.", {
							position: toast.POSITION.TOP_CENTER
						});
						setTimeout(function () {
							history.push('/admin/listconsumer');
						}, 800)
						setReciveStatus(false)
					}
					else if (res.data.error == true) {
						setReciveStatus(false)
						toast.error("Connector details is already exists.", {
							position: toast.POSITION.TOP_CENTER
						});
					}
					else {
						setReciveStatus(false)
						toast.error("Something went wrong.Please try again.", {
							position: toast.POSITION.TOP_CENTER
						});
					}
	
				});
			}
			else {
				setReciveStatus(true)
				Api.postRequest3(path.CONSUMER_ADD_URL, formData).then((res) => {	
					setReciveStatus(true)			
					if (res.data.error == false) {						
						toast.success("Consumer has been added successfully.", {
							position: toast.POSITION.TOP_CENTER
						})
						setTimeout(function () {
							history.push('/admin/listconsumer');
						}, 1000)
						setReciveStatus(false)
						reset();
					}
					else if (res.data.error == true) {
						setReciveStatus(false)
						toast.error("Consumer details is already exists.", {
							position: toast.POSITION.TOP_CENTER
						});
					}
					else {
						setReciveStatus(false)
						toast.error("Something went wrong.Please try again.", {
							position: toast.POSITION.TOP_CENTER
						});
					}
					//reset();
				});
			}

			setErrorStatusBanner(false);
			setErrorMsgBanner("");
			setErrorStatusLogo(false);
			setErrorMsgLogo("");
		}else if(reciveData !== "png"  &&  reciveData !=="jpg" &&  reciveData !=="jpeg" && reciveData !==undefined ){
			//console.log("log....1");
			setErrorStatusBanner(true);
			setErrorMsgBanner("Only jpg,jpeg/png format accepted");
			setErrorStatusLogo(false);
			setErrorMsgLogo("");
		} else if((reciveDataLogo !=="png"  && reciveDataLogo!=="jpg"  && reciveDataLogo!=="jpeg" && reciveDataLogo !==undefined)){
			//console.log("log....2");
			setErrorStatusLogo(true);
			setErrorMsgLogo("Only jpg,jpeg/png format accepted");
			setErrorStatusBanner(false);
			setErrorMsgBanner("");
		}
 

	
	}
	let editRealEstateValue = editdata.agent_name || "DEFAULT";
	let editThirdPartyValue = editdata.third_party_crm_id || "DEFAULT";
	let editInternalValue = editdata.internal_crm_id || "DEFAULT";

	const deleteBanner = async(id)  =>{
		
		await Api.getRequest(path.CONSUMER_IMAGE_LOGO_DELETE_URL+"banner/"+id,'').then((res)=>{
			if(res.data.error === false)
			{
				getagentDropDown();
				toast.success("Banner has been deleted successfully.",{
					position: toast.POSITION.TOP_CENTER
				},()=>getagentDropDown());					
			}				
			else
			{
				toast.error("Something went wrong.Please try again.",{
					position: toast.POSITION.TOP_CENTER
				});
			}	
		});
   } 

   const deleteLogo = async(id)  =>{
	
	await Api.getRequest(path.CONSUMER_IMAGE_LOGO_DELETE_URL+"logo/"+id,'').then((res)=>{
		if(res.data.error === false)
		{
			getagentDropDown();
			toast.success("Logo has been deleted successfully.",{
				position: toast.POSITION.TOP_CENTER
			},()=>getagentDropDown());					
		}				
		else
		{
			toast.error("Something went wrong.Please try again.",{
				position: toast.POSITION.TOP_CENTER
			});
		}	
	});
} 




function copyUrl() {
	
    var copyText = document.getElementById("pwd_spn");
    var textArea = document.createElement("textarea");
    textArea.value = copyText.textContent;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("Copy");
    textArea.remove();
}

function copyulClick (){
	document.getElementById("cp_btn").addEventListener("click", copyUrl)
}
  
function onColorChange (e){
setcolorcode(e.target.value)
} 
function onChange(e) {
    setPagecontent(e.target.value);
	setValue('pagecontent', e.target.value);
}
function onChange1(e) {
	let contentsTerms = e.target.value;	
	let termCond = contentsTerms.replace(/(<([^>]+)>)/ig, '')
	if(termCond =="")
	{
		setTermscondition("");
		setValue('termscondition', "");
	}
	else
	{
		setTermscondition(contentsTerms);
		setValue('termscondition', e.target.value);
	}
	
	
}
function onChange2(e) {
	setFootercontent(e.target.value);
	setValue('footercontent', e.target.value);
}
const cpBg = {backgroundColor: `${colorcode}` }
	return (
	
		<>
		{reciveStatus ===true && <Loading/>}
			<nav aria-label="breadcrumb">
				<ol className="breadcrumb">
					<li className="breadcrumb-item"><a href="#" className="breadcrumb-link">Dashboard</a></li>
					<li className="breadcrumb-item active" aria-current="page">Add Direct To Consumer</li>
				</ol>
			</nav>
			<form method="POST" id="AddConnector" onSubmit={handleSubmit(onSubmit)} enctype="multipart/form-data" >
				<div className="card">
					<div className="card-body">
						<div className="mb-3">
							<h5 className="hed01">{(parameter.id != undefined) ? 'Edit' : 'Add'} Direct To Consumer  <span className="tx11"></span></h5>

						</div>


						<div className="accordion" id="accordionExample1">





							<div className="card">
								<div className="card-body">
									<div className="row el_gr">
										<div className="col-sm-6 col-lg-6">
											<div className="el">
												<h2 className="mb-2">
													<label className="hetx01"> Title</label>
												</h2>
												<div>
													<input type="text" defaultValue={title}
													 {...register("title", { required: true })} className="form-control" />
													{errors.title && errors.title.type == "required" && <div className="errorMsg">Title is required.</div>}
												</div>
											</div>
										</div>


										<div className="col-sm-6 col-lg-6">
											<div className="el">
												<h2 className="mb-2">
													<label className="hetx01"> Choose Real Estate Agent</label>
												</h2>
												<div>
													<select defaultValue={agent_name} {...register("agent_name", { required: true })} onChange={handleChanges} id="agentid" className="form-control">
														<option value="">Select Real Estate Agent</option>
														{agentdata1.map((data, index) => (														
															<option selected={data.id === agent_name}
															value={data.id} key={index}>{data.agentName}</option>
														))}
													</select>
													{errors.agent_name && errors.agent_name.type == "required" && <div className="errorMsg">Real Estate Agent is required.</div>}
												</div>
											</div>
										</div>
										<div className="col-sm-6 col-lg-6">
											<div className="el">
												<h2 className="mb-2">
													<label className="hetx01">  Choose Internal CRM</label>
												</h2>
												<div>
													<select defaultValue={internalid} 
													{...register("internalid", 
													{ required: true })} className="form-control"
													 style={{ display: "inline-block", maxwidth: "360px" }}>
														<option value="">Select Internal CRM</option>
														{dropdown2.map((data2, index2) => (												
															<option selected={data2.id === internalid} 
															value={data2.id} key={index2}>
																{data2.cf_crm.crmName}</option>
														))}
													</select>&nbsp;&nbsp;
													{errors.internalid && errors.internalid.type == "required" && <div className="errorMsg">Internal CRM is required.</div>}</div>
											</div>
										</div>

										<div className="col-sm-6 col-lg-6">
											<div className="el">
												<h2 className="mb-2">
													<label className="hetx01">Status</label>
												</h2>
												<div>
													<select defaultValue={status}
													 {...register("status", { required: true })} 
													 className="form-control"
														style={{ display: "inline-block", maxwidth: "360px" }}  selected={status}>
														<option value="1" selected={status ==="1"}>Active</option>
														<option value="0"  selected={status ==="0"}>InActive</option>

													</select>&nbsp;&nbsp;
													{errors.status && errors.status.type == "required" && <div className="errorMsg">Internal CRM is required.</div>}</div>
											</div>
										</div>
										<div className="col-sm-6 col-lg-6">
											<div className="el">
												<h2 className="mb-2">
													<label className="hetx01"> Country</label>
												</h2>
												<div>
													<select defaultValue={country} {...register("country", { required: true })} id="country" className="form-control">
														<option value="">Select Country</option>
														{CountryCodeOption.map((data, index) => (														
															<option selected={data.id === agent_name}
															value={data.country_code} key={index}>{data.name}</option>
														))}
													</select>
													{errors.country && errors.country.type == "required" && <div className="errorMsg">Country is required.</div>}
												</div>
											</div>
										</div>

									</div>
								</div>
							</div>
							<div className="mb-3">
							<h5 className="hed01">White label setting  <span className="tx11"></span></h5>

						</div>
							<div className="card">
							
							{/*<nav aria-label="breadcrumb">
				<ol className="breadcrumb">
					
					<h4 className="breadcrumb-item active" aria-current="page">White label setting</h4>
				</ol>
														</nav>*/}
								<div className="card-body">
									<div className="row el_gr">
									
									
										
										<div className="col-sm-6 col-lg-6">
											<div className="el">
												<h2 className="mb-2">
													<label className="hetx01">  Company Logo <span>(Dimension  : W-240px, H-52px)</span></label>
												</h2>
												<div>
													<input type="file"
														//onChange={(e)=>handleChangeLogo(e)}
														name='companylogo'
														defaultValue={companylogo}
														{...register("companylogo", { required: (companylogo === "" || companylogo === undefined ) ? true:false,															
														
														})}
														onChange={(e) => handleChangeLogo(e)}
														className="form-control" />
														{errors.companylogo && errors.companylogo.type == "required" && <div className="errorMsg">Company logo is required.</div>}
														{errorStatusLogo === true ?  <div className="errorMsg">{errorMsgLogo}</div>:""}&nbsp; &nbsp;
														<br></br>
														{parameter.mode === "edit" && companylogo !== "" && ( <>
															<a href={baseUrl+companylogo} target='_blank'>
														 <img src={baseUrl+companylogo} width="100" height="100" /></a>
														 {/* <span   className='ml-4' onClick={()=>deleteLogo(parameter.id)} >
													<i className="fa fa-trash" aria-hidden="true"></i>
													</span> */}
													</>)}
													
														
												</div>
											</div>
										</div>

										<div className="col-sm-6 col-lg-6">
											<div className="el">
												<h2 className="mb-2">
													<label className="hetx01">  Company Banner <span>(Dimension  : W-1900px, H-318px)</span></label>
												</h2>
												<div>
													<input type="file"
														//defaultValue={banner_image}
														
														//name='banner_image'
														{...register("banner_image", { required: (banner_image === "" || banner_image=== undefined )? true:false, })}
														onChange={(e) => handleChangeBanner(e)}
														
														className="form-control" />
														
														{errors.banner_image && errors.banner_image.type == "required" && <div className="errorMsg">Company banner is required.</div>}
														{errorStatusBanner === true ?  <div className="errorMsg">{errorMsgBanner}</div>:""} &nbsp; &nbsp; 
														<br></br>
														{parameter.mode === "edit" && banner_image !== "" && ( <>
													<a href={baseUrl+banner_image} target='_blank'>
														 <img src={baseUrl+banner_image} width="100" height="100" /></a>
														 {/* <span className='ml-4' onClick={()=>deleteBanner(parameter.id)} >
													<i className="fa fa-trash" aria-hidden="true"></i>
													</span> */}
													</>)}
												</div>
											</div>
										</div>
										<div className="col-sm-6 col-lg-6">
											<div className="el">
												<h2 className="mb-2">
												<label className="color-selector"></label>
													<label className="hetx01"> Color code</label>
												</h2>
												<div className='d-flex align-items-center'>
												<label className="color-selector me-4 mb-0 border py-2 px-4">
												<span className="circle" style={{ background:colorcode }} />
												<span>{colorcode}</span>
												</label>
													<input type="color" defaultValue={colorcode}
														{...register("colorcode", { required: true })} 
														onChange={(e)=>onColorChange(e)}  className="hidden color-picker" style={cpBg} />

													{errors.colorcode && errors.colorcode.type == "required" && <div className="errorMsg">Color code is required.</div>}

												</div>
												
											</div>
										</div>

										<div className="col-sm-4 col-lg-4">
											<div className="el">
												<h2 className="mb-2">
													<label className="hetx01">Enable Term & Condition</label>
												</h2>
												<div>
													<select defaultValue={enableTermStatus}
													 {...register("enableTermStatus", { required: true })} 
													 onChange={(e)=>handleChangeEnable(e)}
													 className="form-control"
														style={{ display: "inline-block", maxwidth: "360px" }}  selected={enableTermStatus}>
														<option value="1" selected={enableTermStatus ==="1"}>Yes</option>
														<option value="0"  selected={enableTermStatus ==="0"}>No</option>

													</select>&nbsp;&nbsp;
													{errors.enableTermStatus && errors.enableTermStatus.type == "required" && <div className="errorMsg">Enable Term & Condition.</div>}</div>
											</div>
										</div>				
									
									</div>
									{enableTermStatus ==="1" && (<><div className="row el_gr"><div className="col-sm-12 col-lg-12">
											<div className="el">
												<h2 className="mb-2">
													<label className="hetx01"> Term & Condition</label>
												</h2>
												<div>
													{/* <textarea type="text" defaultValue={termscondition}  {...register("termscondition", { required:enableTermStatus ==="1"? true:false })} className="form-control" /> */}
													<Editor value={termscondition}  {...register("termscondition", { required:enableTermStatus ==="1"? true:false })} onEditorStateChange={setValue('termscondition', termscondition)}  onChange={onChange1} />
													{errors.termscondition && errors.termscondition.type == "required" && <div className="errorMsg">Term & Condition is required.</div>}

												</div>
											</div>
										</div></div></>)}
										<div className="row el_gr"><div className="col-sm-12 col-lg-12">
											<div className="el">
												<h2 className="mb-2">
													<label className="hetx01"> Footer Content</label>
												</h2>
												<div>
													{/* <textarea type="text" defaultValue={footercontent}  {...register("footercontent")} className="form-control" /> */}
													<Editor value={footercontent} {...register("footercontent")} onEditorStateChange={setValue('footercontent', footercontent)}  onChange={onChange2} />
												</div>
											</div>
										</div></div>
										<div className="row el_gr"><div className="col-sm-12 col-lg-12">
											<div className="el">
												<h2 className="mb-2">
													<label className="hetx01"> Page Right Content</label>
												</h2>
												<div>
													{/* <textarea type="text" defaultValue={pagecontent}  {...register("pagecontent")} className="form-control" /> */}
													<Editor value={pagecontent} {...register("pagecontent")} onEditorStateChange={setValue('pagecontent', pagecontent)} onChange={onChange} />
												</div>
											</div>
										</div></div>
								</div>

							</div>

						</div>
					</div>
				</div>

{parameter.mode === "edit" && (
					<div className="card">
							
					<div className="card-body">
						<div className="row el_gr">
							<div className="col-sm-12 col-lg-12">
								<div className="el">
									<h2 className="mb-2">
										<label className="hetx01"> Url:</label>
									</h2>
									<div>
									<span id="pwd_spn" className="nem1 mr-4">{path.GATEWAY_URL_HOPMEPAGE}postproperty/{authKeyValue}</span> 
									{/* <span id="cp_btn" onClick={copyulClick}>Copy</span> */}
									<i  id="cp_btn" className="fa fa-copy" style={{fontSize:"24px"}} 
									data-toggle="tooltip" data-placement="top" title=" Double click to Copy"
									onClick={copyulClick}></i>
									
									</div>
								</div>
							</div>
</div></div></div>
)}



				<div className="down_pan">
					<div className="text-right">
						<Link to="/admin/listconsumer"><button className="btn_cf dim">
							<i className="fa fa-times" aria-hidden="true">&nbsp;</i> Cancel</button></Link> &nbsp;

						<input className="btn_cf scss" type="submit" name="Save" value="Save"  disabled={disableSubmit===true?true:false}/>
					</div>
				</div>
			</form>



		</>
	)

}
export default AddConsumer;