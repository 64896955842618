import React from "react";

const CMSFooter = (props) => {
    let reciveDate = new Date();
    return (
        <React.Fragment>
          <div className={props.data=="target-market-determinations"?'footer-bottom2':'footer-bottom'}>
        <div className="container clearfix">
        <p>Real Estate Advance Pty Ltd ACN 164 355 051 Australian Credit Licence 546099 is a non-bank lender specialising in loans to assist vendors cover the marketing costs associated with selling a property and in loans for real estate agents secured against future receivable commissions. Loans are subject to our credit criteria and terms and conditions.</p>
    </div>
        </div>
        </React.Fragment>
    )
}
export default CMSFooter;