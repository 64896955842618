import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import LoginForm from "./LoginForm";
import {
    UserLogin
} from "../../actions/UserAction";
import validateLoginForm from "../validation_rules/LoginFormRules";
import { encrypt } from "../../Utility/Utility";

class Login extends Component {
    initialState = {
        email: "",
        password: "",
        time: Math.floor(Date.now() / 1000)
    }

    state = {
        errors: {},
        login: this.initialState,
        isVisible: false
    }

    switchSpecificClient = (loginData) => {
      
       // debugger;
        if (
            loginData === null ||
            loginData === undefined ||
            Object.keys(loginData).length === 0||
            loginData.success === 0 
        ) {
            let message = loginData && loginData.message;
            //console.log("token:::",loginData.message);
            //toast.success(message === undefined ?  loginData.validation_error && loginData.validation_error.password[0]: message);
            toast.warn("Unable to login Something went worng")
        } else {
            localStorage.setItem("authenticateData", JSON.stringify(loginData));
            localStorage.setItem("token", loginData.token);
           
            toast.success("Login successfully!");
            this.props.history.push("/admin/listrealestate");
           }
    };

    handleChange = (e) => {
       
        const{errors}=this.state;
        delete[errors]
        this.setState({
            ...this.state,
            login: {
                ...this.state.login,
                [e.target.name]: e.target.value
            }

        });
    }

    showPassword = () => {
        this.setState({ isVisible: !this.state.isVisible, error: {} })
    }

    checkAuthentication = async () => {
        const { email, password } = this.state.login;
        let errors = validateLoginForm({ email, password });
 
        if (Object.entries(errors).length > 0) {
            this.setState({
                ...this.state,
                errors: errors
            })
        } else {
            let time = Math.floor(Date.now() / 1000);
            let encrptionKey = "IBINPRJ_"+ time;
           // let pass = encrypt(password, encrptionKey);
           let pass =password;
            let params = {
                //login_params: `email=${email}&password=${pass}&time=${time}`,
                login_params: `email=${email}&password=${pass}`,
            }
            await this.props.UserLogin(params);
            this.switchSpecificClient(this.props.loginData);
        }
    }

    handleKeyDown = (e) => {
        if (e.key === "Enter" || e.keyCode === 13) {
            this.checkAuthentication();
        }
    }

    render() {
        return (
            <>
                <LoginForm
                    login={this.state.login}
                    errors={this.state.errors}
                    isLoding={this.props.pending}
                    handleChange={this.handleChange}
                    handleKeyDown={this.handleKeyDown}
                    checkLogin={(e) => this.checkAuthentication(e)}
                    showPassword={this.showPassword}
                />
            </>
        );
    }

}
const mapStateToProps = (state) => {
    let {
        UserReducer: { pending, error, loginData },
    } = state;

    return { pending, error, loginData };
};

Login.propTypes = {
    UserLogin: PropTypes.func,
    loginData: PropTypes.object,
    pending: PropTypes.bool,
    error: PropTypes.object,
};

export default connect(mapStateToProps, {
    UserLogin
})(Login);


