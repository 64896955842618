import { isEmail, isLength } from "validator";
import messages from "../common/messages";

export default function validateEstateAgentsForm({ address, 
    agentname,
    contactnumber,
    emailid,
    address2,
    state,
    zipcode, }) {
    let errors = {};
   
    // if (!emailid) {
    //     errors.emailid = messages["emailid.required"];
    // } else if (!isEmail(emailid) && !isLength(emailid, { min: 10, max: 50 })) {
    //     errors.emailid = messages["emailid.invalid"];
    // }
   
    // if (!contactnumber) {
    //     errors.contactnumber = messages["contactnumber.required"];
    //   } else if (!isLength(contactnumber, { min: 10, max: 14 })) {
    //     errors.contactnumber = messages["contactnumber.invalid"];
    //   } 
   
    //   if (!address2) {
    //     errors.address2 = messages["address2.required"];
    // }
    // if (!state) {
    //     errors.state = messages["state.required"];
    // }
    // if (!zipcode) {
    //     errors.zipcode = messages["zipcode.required"];
    // }
    // if (!address) {
    //     errors.address = messages["address.required"];
    // }
    if (!agentname) {
        errors.agentname = messages["agentname.required"];
    }
    return errors;
}