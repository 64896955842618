import React, { Component} from 'react';
import { Link } from 'react-router-dom';
import JSONViewer from 'react-json-viewer';
const LogConsumerDetails=(props)=>{
let modiFiedValue =Object.values(props?.logconsumerDetail)

  return (
    <>
      
<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item"><a href="#" className="breadcrumb-link">Dashboard</a></li>
						<li className="breadcrumb-item active" aria-current="page">Log Details</li>
					</ol>
				</nav>
				{modiFiedValue && modiFiedValue.length > 0 && (
                               modiFiedValue.map((item, index) => (
				<div className="card">
					<div className="card-body">
						<div className="d-flex align-items-center justify-content-between mb-1">
							<h5 className="hed01"><u>Log Details For {item.apiType.replaceAll('_', ' ')}</u></h5>
							
						</div>	
						<div className="row el_gr">
							<div className="col-sm-6 col-lg-4">
								<div className="el">
									<label><b>CRM:</b> <span>{item.logType}</span></label><br></br>	
                                    
								</div>
							</div>
							<div className="col-sm-6 col-lg-4">
                            <div className="el">
									<label><b>Added Date:</b> <span>{new Date(parseInt(item.addDate)).toDateString()}</span>	</label> <br></br>	
								
								</div>
							</div>
							<div className="col-sm-6 col-lg-4">
                            <div className="el">
									<label><b>Direct To Consumer Name:</b> <span>{item.cf_direct_to_consumer?.title}</span></label><br></br>		   
								</div>
							</div>
							
							<div className="col-sm-6 col-lg-4">
                            <div className="el">
									<label><b>Action:</b> <span>{item?.apiType}</span></label><br></br>		
									
									
								</div>
							</div>
							
                            {/* <div className="el">
									<label><b>Third Party Id:</b> <span>{item?.propertyId}</span>	</label><br></br>	
					
								</div> */}
							
							<div className="col-sm-6 col-lg-4">
                            <div className="el">
									<label><b>Internal Id:</b> <span>{(item.logType!=="Vault" && JSON.parse(item.responseData)?.id)? JSON.parse(item?.responseData)?.id:"---"}</span>	</label><br></br>	
					
								</div>
							</div>
							<div className="col-sm-6 col-lg-4">
                            <div className="el">
									<label><b>Status:</b> <span>{item?.status}</span></label>	<br></br>	
									
									
                                    
								</div>
							</div>
							<div className="col-sm-12 col-lg-12">
                            <div className="el" style={{overflow:"auto",maxheight:'200px'}}>
									<label><b>Request Data:</b></label>	<br></br>	
									{/* <span>{item?.requestData}</span> */}
									{ item?.requestData !== null ? (
									// <JSONViewer json={ JSON.parse(item?.requestData) } />
									<span>{item?.requestData}</span>
									):(
										<span>Null</span>
									)}
									{/* <input className="form-control" type="text"
                                    readOnly
                                    value={item.requestData}
                                    /> */}
                                    
								</div>
							</div>

                           
							<div className="col-sm-12 col-lg-12">
                            <div className="el" style={{overflow:"auto",maxHeight:'200px'}}>
									<label><b>Response Data:</b></label><br></br>	
									{/* <span>{(item?.responseData)}</span> */}
									{ item?.responseData !== null ? (
									// <JSONViewer json={ JSON.parse(item?.responseData) } />
									<span>{(item?.responseData)}</span>
									):(
										<span>Null</span>
									)}

								</div>
							</div>
                           
                           
							<div className="col-sm-6 col-lg-4">
							</div>
						</div>
					</div>
				</div>
)))}
				<div className="down_pan">
					<div className="text-right">
						{/* <Link to="/admin/listGlobleLog"> */}
							<button className="btn_cf dim" onClick={(e) => props.ogBackTolIST(e)} ><i className="fa fa-times" aria-hidden="true">&nbsp;</i> Cancel</button>
							{/* </Link> */}
							 &nbsp;
						{/* <button className="btn_cf scss"  onClick={(e) => props.ogBackTolIST(e)}>Back to list </button> */}
						 {/* <button className="btn_cf scss"  onClick={() => props.onLogHistory()}>
                            <i className="fal fa-save"></i>&nbsp;{props.agentsReciveDataById.id ? "Update":"Save"} </button>
							 onclick="history.back()"
							*/}
					</div>
				</div>

    </>
)
                        
      }
export default LogConsumerDetails;