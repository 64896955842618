import React from "react";

const HomeFooter = (props) => {
    let reciveDate = new Date();
    return (
        <React.Fragment>
          <footer>
    {/* <div className="containerFullWidth grayBg">
        <div className="container">
            <div className="row">
                <div className="col-12 text-center">
                    <p className="mt-3" style={{"font-size": "14px"}}>Copyright © {reciveDate.getFullYear()} campaignflow. All rights reserved</p>
                </div>
            </div>
        </div>
    </div> */}
    <div className="container">
        <div className="row">
            <div className="col-12 ">                                 
                    <div  className="mt-3 " style={{"font-size": "14px"}} dangerouslySetInnerHTML={{ __html: props.data }}></div>
                {/* Real Estate Advance Pty Ltd ACN 164 355 051 Australian Credit Licence 546099,
                 trading as Campaign Flow is a non-bank lender specialising in loans to assist vendors cover
                  the marketing costs associated with selling a property. Loans are subject to our credit criteria 
                  and terms and conditions. We recommend that you read our <a href="https://campaignflow.com.au/privacy-consent/" target="_blank"> Privacy Consent</a>, <a href="https://campaignflow.com.au/privacy-policy/" target="_blank"> Privacy Policy</a>,
                  <a href="https://campaignflow.com.au/credit-guide/" target="_blank"> Credit Guide</a>, <a href="https://campaignflow.com.au/electronic-communication-consent/" target="_blank"> Electronic Communication Policy</a>  and <a href="https://realflowfinance.com.au/target-market-determinations/" target="_blank">  Target Market Determination</a> before starting. */}
                <br></br> <br></br>
            </div>
        </div>
    </div>
</footer>
        </React.Fragment>
    )
}
export default HomeFooter;