
//export const PENDING ="PENDING";
//export const ERROR ="ERROR";
export const ADD_REAL_ESTATE = "ADD_REAL_ESTATE";
export const ADD_REAL_ESTATE_SUCCESS = "ADD_REAL_ESTATE_SUCCESS";
export const FETCH_REAL_ESTATE = "FETCH_REAL_ESTATE";
export const FETCH_REAL_ESTATE_SUCCESS = "FETCH_REAL_ESTATE_SUCCESS";
export const UPDATE_REAL_ESTATE = "UPDATE_REAL_ESTATE";
export const UPDATE_REAL_ESTATE_SUCCESS = "UPDATE_REAL_ESTATE_SUCCESS";
export const UPDATE_FETCH_REAL_ESTATE = "UPDATE_FETCH_REAL_ESTATE";
export const UPDATE_FETCH_REAL_ESTATE_SUCCESS = "UPDATE_FETCH_REAL_ESTATE_SUCCESS";

//Third party

export const FETCH_THIRD_ESTATE = "FETCH_THIRD_ESTATE";
export const FETCH_THIRD_ESTATE_SUCCESS = "FETCH_THIRD_ESTATE_SUCCESS";
export const FETCH_AGENTS_DROP_DAWN = "FETCH_AGENTS_DROP_DAWN";
export const FETCH_AGENTS_DROP_DAWN_SUCCESS = "FETCH_AGENTS_DROP_DAWN_SUCCESS";
export const UPDATE_THIRD_PARTY = "UPDATE_THIRD_PARTY";
export const UPDATE_THIRD_PARTY_SUCCESS = "UPDATE_THIRD_PARTY_SUCCESS";
export const UPDATE_THIRD_PARTY_GET = "UPDATE_THIRD_PARTY_GET";
export const UPDATE_THIRD_PARTY_GET_SUCCESS = "UPDATE_THIRD_PARTY_GET_SUCCESS";


//Internal CRM

export const FETCH_INTERNAL_LIST = "FETCH_INTERNAL_LIST";
export const FETCH_INTERNAL_LIST_SUCCESS = "FETCH_INTERNAL_LIST_SUCCESS";
export const UPDATE_INTERNAL = "UPDATE_INTERNAL";
export const UPDATE_INTERNAL_SUCCESS = "UPDATE_INTERNAL_SUCCESS";
export const UPDATE_INTERNAL_GET = "UPDATE_INTERNAL_GET";
export const UPDATE_INTERNAL_GET_SUCCESS = "UPDATE_INTERNAL_GET_SUCCESS";

//CRM Drop dawn

export const FETCH_CRM_EXTERNAL_DROP_DAWN = "FETCH_CRM_EXTERNAL_DROP_DAWN";
export const FETCH_CRM_EXTERNAL_DROP_DAWN_SUCCESS = "FETCH_CRM_EXTERNAL_DROP_DAWN_SUCCESS";
export const FETCH_CRM_INTERNAL_DROP_DAWN = "FETCH_CRM_INTERNAL_DROP_DAWN";
export const FETCH_CRM_INTERNAL_DROP_DAWN_SUCCESS = "FETCH_CRM_INTERNAL_DROP_DAWN_SUCCESS";

//LOG HISTORY

export const FETCH_LOG_HISTORY = "FETCH_LOG_HISTORY";
export const FETCH_LOG_HISTORY_SUCCESS = "FETCH_LOG_HISTORY_SUCCESS";
export const FETCH_LOG_HISTORY_DETAILS = "FETCH_LOG_HISTORY_DETAILS";
export const FETCH_LOG_HISTORY_DETAILS_SUCCESS = "FETCH_LOG_HISTORY_DETAILS_SUCCESS";

//Connector drop down
export const FETCH_CONNECTOR_DROP_DAWN = "FETCH_CONNECTOR_DROP_DAWN";
export const FETCH_CONNECTOR_DROP_DAWN_SUCCESS = "FETCH_CONNECTOR_DROP_DAWN_SUCCESS";

//log history
export const FETCH_CONSUMER_DROP_DAWN = "FETCH_CONSUMER_DROP_DAWN";
export const FETCH_CONSUMER_DROP_DAWN_SUCCESS = "FETCH_CONSUMER_DROP_DAWN_SUCCESS";

export const FETCH_LOG_HISTORY_DETAILS_CONSUMER = "FETCH_LOG_HISTORY_DETAILS_CONSUMER";
export const FETCH_LOG_HISTORY_DETAILS_CONSUMER_SUCCESS = "FETCH_LOG_HISTORY_DETAILS_CONSUMER_SUCCESS";

export const FETCH_LOG_DETAILS_CONSUMER = "FETCH_LOG_DETAILS_CONSUMER";
export const FETCH_LOG_DETAILS_CONSUMER_SUCCESS = "FETCH_LOG_DETAILS_CONSUMER_SUCCESS";
