import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import AddEmailSetUp from "./AddEmailsetUp"
import {AddRealAgentData, FetchRealAgentsList, FetchRealAgentById, UpdateRealAgentData,DeleteAgents} from "../../../../actions/RealEstateAction"

class ManageEmailSetup extends Component {
  constructor() {
    super();

  }
initState ={
  agentname:"",
  address:"",
  contactnumber:"",
  emailid:"",
  address2:"",
  state:"",
  zipcode:"",
}
 
state={
  agentState:this.initState,
  mode:"List",
  errors: {},
  pageNumber:1,
  pageSize:10,
  totalElements: 20,
  searchText: "",
  popupModalType: 0,
  popupTitle: "",
  deleteId:"",
  totallPage:""
}

onDataInputFieldHnadleChange=(e)=>{
  const { errors } = this.state;
    delete errors[e.target.name];
  this.setState({
    ...this.state,
    agentState: {
      ...this.state.agentState,
      [e.target.name]: e.target.value

    }
  })
}

// onAddRealEstate = ()=>{
//   const { agentname,
//   address,
//   contactnumber,
//   emailid,
//   address2,
//   state,
//   zipcode, } = this.state.agentState;
//   let errors = validateEstateAgentsForm({ agentname,
//     address,
//     contactnumber,
//     emailid,
//     address2,
//     state,
//     zipcode, });
 
//   if (Object.entries(errors).length > 0) {
//       this.setState({
//           ...this.state,
//           errors: errors
//       })
//   } else{
//     const params = {
//       data: this.state.agentState
//     }
//    const upDateParams={
//     data:this.state.agentState,
//     id:this.props.agentsReciveDataById.id
//    }
//     if(this.props.agentsReciveDataById.id){
//       this.props.UpdateRealAgentData(upDateParams)
//       this.props.history.push("/admin/listrealestate")
//     }else{
//       this.props.AddRealAgentData(params);
//       this.props.history.push("../admin/listrealestate")
//     }
    
//   } 
// }

// searchHandleChange = (e) => {
//   this.setState({ ...this.state, searchText: e.target.value }
//     ,()=>{console.log("this is search klog....",this.state.searchText);});
// };
// onSearchFormdata = async(pageNumber, pageSize) => {
//   this.setState({
//     ...this.state,
//     pageNumber: pageNumber,
//     pageSize: pageSize,
//     //goToPageNumber: pageNumber,
   
//   });
//   const params = {
//     page: pageNumber,
//     size: pageSize,
//     searchText: this.state.searchText,
   
//   };
//   // const cbLoading = () => {
//   //   this.setState({
//   //     isLoading: "DONE",
//   //   });
//   // };
//   await this.props.FetchRealAgentsList(params);
//   const {count,size}=this.props.paginationData;
//  let totalPage=Math.ceil(+count/+size)
//   this.setState({
//     totallPage: totalPage,
//   });
// };









componentDidMount(){
//   let id=this.props.match.params?.id
//  //this.props.FetchRealAgentsList();
//  this.onSearchFormdata(this.state.pageNumber, this.state.pageSize)
//  const params = {
//   data:id
// }
//  this.props.FetchRealAgentById(params)

}

// getDataOnEditMode = ()=>{
//   this.setState({
//     ...this.state,
//     agentState:{
//       ...this.state.agentState,
//   agentname:this.props.agentsReciveDataById?.agentName,
//   address:this.props.agentsReciveDataById?.agentAddress,
//   contactnumber:this.props.agentsReciveDataById?.agentContact,
//   emailid:this.props.agentsReciveDataById?.agentEmailId,
//   address2:this.props.agentsReciveDataById?.agentAddress2,
//   state:this.props.agentsReciveDataById?.agentState,
//   zipcode:this.props.agentsReciveDataById?.agentZipcode,

//     }
//   })
// }
// componentDidUpdate(prevProps, prevState){
//   if (this.props.agentsReciveDataById.id !== prevProps.agentsReciveDataById.id) {

//     this.getDataOnEditMode();
//   }
//   if (this.state.searchText !== prevState.searchText) {
//     if (this.state.searchText.length > 2) {
//       this.onSearchFormdata(1, this.state.pageSize);
//     } else if (this.state.searchText.length === 0) {
//       this.onSearchFormdata(1, this.state.pageSize);
//     }
//   }
  
// }
  render() {
 
    return (
      <React.Fragment>
         <AddEmailSetUp
      // agentState={this.state.agentState}
       //onDataInputFieldHnadleChange={this.onDataInputFieldHnadleChange}
       //onAddRealEstate={this.onAddRealEstate}
      // onEditClick={this.onEditClick}
      //agentsReciveDataById={this.props.agentsReciveDataById}
      //errors={this.state.errors}
       />
        {/* {this.props.location.pathname === "/admin/addrealestate"  &&
       <AddEmailSetUp
       agentState={this.state.agentState}
       onDataInputFieldHnadleChange={this.onDataInputFieldHnadleChange}
       onAddRealEstate={this.onAddRealEstate}
      // onEditClick={this.onEditClick}
      agentsReciveDataById={this.props.agentsReciveDataById}
      errors={this.state.errors}
       />
    } */}

{/* { this.props.location.pathname.includes("/admin/addrealestate/edit") &&
       <AddEmailSetUp
       agentState={this.state.agentState}
       onDataInputFieldHnadleChange={this.onDataInputFieldHnadleChange}
       onAddRealEstate={this.onAddRealEstate}
      // onEditClick={this.onEditClick}
      agentsReciveDataById={this.props.agentsReciveDataById}
      errors={this.state.errors}
       />
    } */}
       
      </React.Fragment>
    );
  }
}

//export default ManageSpecies;

const mapStateToProps = (state) => {
  let {
    AgentsReducer: {
      pending,
      error,
      agentsReciveData,
      agentsReciveDataById,
      paginationData
    }
  } = state;

  return {
    pending:pending,
      error:error,
      agentsReciveData:agentsReciveData,
      agentsReciveDataById:agentsReciveDataById,
      paginationData:paginationData
  };
};

ManageEmailSetup.propTypes = {
  AddRealAgentData:PropTypes.func,
  FetchRealAgentsList:PropTypes.func,
  FetchRealAgentById :PropTypes.func,
  UpdateRealAgentData:PropTypes.func,
  DeleteAgents:PropTypes.func,
  //history: PropTypes.object,
  pending: PropTypes.bool,
  error: PropTypes.object,
};
export default connect(mapStateToProps, {
  AddRealAgentData,
  FetchRealAgentsList,
  FetchRealAgentById,
   UpdateRealAgentData,
   DeleteAgents
})(ManageEmailSetup);