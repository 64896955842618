import React from "react";
import { Switch, withRouter } from "react-router-dom";
import HomeLayout from "./HomeLayout";
import LoginLayout from "./LoginLayout";
import CMSLayout from "./CMSLayout";
import HomePage from "../module/admin/Comman/HomePage";
import CMSPage from "../module/admin/frontEnd/CMSPage";


const index = (props) => {
     let { pathname } = props.location;
    
    let header = <HomeLayout {...props} />
 if (pathname.includes("admin/login") === true) { 
        header = <LoginLayout {...props} />
    } else if(pathname==='/'){
        header = <LoginLayout {...props} />
    }else if(pathname.includes("/postproperty")){
        header=<HomePage {...props}/>
    }else if(pathname.includes("/privacy-consent") || pathname.includes("/privacy-policy") || pathname.includes("/credit-guide") || pathname.includes("/electronic-communication-consent") || pathname.includes("/target-market-determinations")){
        header = <CMSLayout {...props} />
        header=<CMSPage {...props}/>
    }
    return <Switch>{header}</Switch>
};
export default withRouter(index);
