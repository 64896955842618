
import {
    PENDING,
    ERROR,
    LOGIN_SUCCESS,
    LOGOUT_SUCCESS
} from "../actions/types";

const initialState = {
    pending: false,
    error: {},
    loginData: {},
};

export const UserReducer = function (state = initialState, action) {
    switch (action.type) {
        case PENDING:
            return {
                ...state,
                pending: true,
            };
        case ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };

        case LOGIN_SUCCESS:
            return {
                ...state,
                pending: false,
                loginData: action.payload
            };

        case LOGOUT_SUCCESS:
            return {
                ...state,
                pending: false,
                loginData: action.payload,
            };
        default:
            return state;
    }
};
