import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { propTypes } from "react-bootstrap/esm/Image";
import ListConnectrs from "./ListConnecters";
import AddConnecters from "./AddConnecters"; 
import { Link } from "react-router-dom";

class ManageConnectrs extends Component {
  constructor() {
    super();
  }
  initState = {
    // agentid: 0,
    // crmname: "",
    // environment: "1",
    //  baseurl: "",
    // authtokendpoint: "",
    // clientid: "",
    // clientsecretkey: "",
    // otherinformation: "",
  }

  state = {
   // agentState: this.initState,
    mode: "List",
    errors: {},
  }

//   onDataInputFieldHnadleChange = (e) => {
//     const { errors } = this.state;
//     delete errors[e.target.name];
//     this.setState({
//       ...this.state,
//       agentState: {
//         ...this.state.agentState,
//         [e.target.name]: e.target.value

//       }
//     })
//   }
//   onAddClick = () => {
//     //this.props.history.push('../admin/addrealestate')
//     this.setState({
//       ...this.state,
//       mode: "Add"
//     }, () => { console.log("this.yutuui"); })
//   }
//   onAddRealEstate = () => {
//     const { agentid,
//       crmname,
//       environment,
//        baseurl,
//       authtokendpoint,
//       clientid,
//       clientsecretkey,
//       otherinformation } = this.state.agentState;
//     let errors = validateInternalCrmForm({
//       agentid,
//       crmname,
//       environment,
//        baseurl,
//       authtokendpoint,
//       clientid,
//       clientsecretkey,
//       otherinformation
//     });

//     if (Object.entries(errors).length > 0) {
//       this.setState({
//         ...this.state,
//         errors: errors
//       })
//     } else {
//       const params = {
//         data: this.state.agentState
//       }
//       const upDateParams={
//         data:this.state.agentState,
//         id:this.props.internalById.id
//        }
//        if(this.props.internalById.id){
//         this.props.UpdateInternalData(upDateParams)
//         this.props.history.push("/admin/listinternalcrm")
//       }else{
//       this.props.AddInternalData(params);
//       this.props.history.push("../admin/listinternalcrm")
//       }
//     }
//   }
//   onEditClick = async (reciveId) => {
//     //console.log("consle.....");
//     const params = {
//       data: reciveId
//     }
//     await this.props.FetchInternalById(params)
//     // this.setState({
//     //   ...this.state,
//     //   agentState: this.props.thirdPartyById
//     // })
//   }
//   componentDidMount() {
//     let id=this.props.match.params?.id
//     this.props.FetchInternalList();
//     this.props.FetchAgentsOption();
//     this.props.FetchCrmInternalOption();
//     const params = {
//       data:id
//     }
//      this.props.FetchInternalById(params)

//   }

//   getDataOnEditMode =async () => {
//     const params = {
//       data: this.props.internalById?.id
//     }
//     await this.props.FetchInternalById(params)
//     this.setState({
//       ...this.state,
//       agentState: {
//         ...this.state.agentState,
//         agentid: this.props.internalById?.agentId,
//         crmname: this.props.internalById?.crmId,
//         environment: this.props.internalById?.environment,
//         baseurl: this.props.internalById?.baseUrlLink,
//         authtokendpoint: this.props.internalById?.authtokendPoint,
//         clientid: this.props.internalById?.clientId,
//         clientsecretkey: this.props.internalById?.clientSecretKey,
//         otherinformation: this.props.internalById?.otherInformation,

//       }
//     })
//   }
//   componentDidUpdate(prevProps, prevState) {
//     if (this.props.internalById.id !== prevProps.internalById.id) {

//       this.getDataOnEditMode();
//     }

//   }
  render() {
   // console.log("this is reducer data",this.props.thirdPartyById);
    return (
      <React.Fragment>
        {this.props.location.pathname === "/admin/listconnecters/addconnecters" &&
          <AddConnecters
            // agentState={this.state.agentState}
            // onDataInputFieldHnadleChange={this.onDataInputFieldHnadleChange}
            // onAddRealEstate={this.onAddRealEstate}
            // errors={this.state.errors}
            // internalById={this.props.internalById}
            // agentsDropDawn={this.props.agentsDropDawn}
            // crmInternalDropDawn={this.props.crmInternalDropDawn}
          />
        }

        {this.props.location.pathname.includes("admin/listconnecters/addconnectors/edit") &&
          <AddConnecters
            // agentState={this.state.agentState}
            // onDataInputFieldHnadleChange={this.onDataInputFieldHnadleChange}
            // onAddRealEstate={this.onAddRealEstate}
            // errors={this.state.errors}
            // internalById={this.props.internalById}
            // agentsDropDawn={this.props.agentsDropDawn}
            // crmInternalDropDawn={this.props.crmInternalDropDawn}
          />
        }
        {this.props.location.pathname === "/admin/listconnecters" &&
          <ListConnectrs
        //   internalList={this.props.internalList}
        //     onAddClick={this.onAddClick}
        //     onEditClick={this.onEditClick}
          />

          //  {totalElements > PAGE_SIZE && (
          //   <Pagination
          //     pageNumber={this.props.pageNumber}
          //     pageSize={props.pageSize}
          //     totalElements={totalElements}
          //     totalPages={totalPages}
          //     updateView={props.updateView}
          //     value={props.goToPageNumber}
          //     handlePageChangeValue={props.handlePageChangeValue}
          //     goToPage={props.goToPage}
          //   />
          // )}
        }
      </React.Fragment>
    );
  }
}

//export default ManageSpecies;

const mapStateToProps = (state) => {
  let {
    AgentsReducer: {
      pending,
      error,
    //   internalList,
    //   internalById,
    //   agentsDropDawn,
    //   crmInternalDropDawn
    }
  } = state;

  return {
    pending: pending,
    error: error,
    // internalList: internalList,
    // internalById: internalById,
    // agentsDropDawn:agentsDropDawn,
    // crmInternalDropDawn:crmInternalDropDawn
  };
};

ManageConnectrs.propTypes = {
//   AddInternalData: PropTypes.func,
//   FetchInternalList: PropTypes.func,
//   FetchAgentsOption: PropTypes.func,
//   FetchCrmInternalOption:PropTypes.func,
//   FetchRealAgentById: PropTypes.func,
//   FetchInternalById:PropTypes.func,
//   UpdateInternalData:PropTypes.func,
//   UpdateRealAgentData: PropTypes.func,
  //history: PropTypes.object,
  pending: PropTypes.bool,
  error: PropTypes.object,
};
export default connect(mapStateToProps, {
//   AddInternalData,
//   FetchInternalList,
//   FetchAgentsOption,
//   FetchCrmInternalOption,
//   FetchInternalById, 
//   UpdateInternalData
})(ManageConnectrs);
