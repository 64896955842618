import React, { Component, useState,useEffect} from 'react';
import { Link } from 'react-router-dom';
import Loading from '../../../common/Loading';

const AddInternal=(props)=>{
	const [reciveStatus,setReciveStatus]= useState(true)
  const{
    agentid,
      crmname,
      environment,
       baseurl,
      authtokendpoint,
      clientid,
      clientsecretkey,
      otherinformation,
	  updateStatusTime,
	  status
  }=props.agentState
  useEffect(() =>{
	setTimeout(() => {
		setReciveStatus(false);
	}, 1000);    
},[]);
  return (
    <>
	   {reciveStatus ===true && <Loading/>}       
       <nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item"><a href="#" className="breadcrumb-link">Dashboard</a></li>
						<li className="breadcrumb-item active" aria-current="page">Internal CRM</li>
					</ol>
				</nav>
				<div className="card">
					<div className="card-body">
						<div className="d-flex align-items-center justify-content-between mb-1">
							<h5 className="hed01">Internal CRM</h5>
							
						</div>	
						<div className="row el_gr">
							<div className="col-sm-6 col-lg-4">
								<div className="el">
									<label>Select Real estate Agent *</label>	
									<select className="form-control"
                                    name="agentid" value={agentid}
                                    onChange={(e) => props.onDataInputFieldHnadleChange(e)}
                                    >
										<option value=" ">--Select--</option>
										{props.agentsDropDawn && props.agentsDropDawn.length > 0 && (
                                        props.agentsDropDawn.map((item,index) => (
										<option key={index} value={item.id}>{item.agentName}</option>
				                         )))}
									</select>
									<div className="errorMsg">{props.errors["agentid"]}</div>
								</div>
							</div>
							<div className="col-sm-6 col-lg-4">
								<div className="el">
									<label>CRM Name *</label>	
									{/* <input className="form-control" type="crmname" name="crmname" value={crmname}
                        onChange={(e) => props.onDataInputFieldHnadleChange(e)}/> */}

								<select className="form-control"
                                    name="crmname" value={crmname}
                                    onChange={(e) => props.onCrmFieldHnadleChange(e)}
                                    >
										<option value="0">--Select--</option>
										{props.crmInternalDropDawn && props.crmInternalDropDawn.length > 0 && (
                                        props.crmInternalDropDawn.map((item,index) => (
										<option key={index} value={item.id}>{item.crmName}</option>
				                         )))}
									</select>

						<div className="errorMsg">{props.errors["crmname"]}</div>
								</div>
							</div>
							<div className="col-sm-6 col-lg-4">
								<div className="el">
									<label>Environment *</label>	
									<select className="form-control"
                                    name="environment" value={environment}
                                    onChange={(e) => props.onDataInputFieldHnadleChange(e)}
                                    >
										<option value="1">Live</option>
										{/* <option value="2">Test</option> */}
									</select>
									<div className="errorMsg">{props.errors["environment"]}</div>
								</div>
							</div>
							<div className="col-sm-6 col-lg-4">
								<div className="el">
									<label>Base URL *</label>	
									<input className="form-control" type="text" 
                                    name="baseurl" value={baseurl}
                                    onChange={(e) => props.onDataInputFieldHnadleChange(e)}
                                    />
									<div className="errorMsg">{props.errors["baseurl"]}</div>
								</div>
							</div>
							<div className="col-sm-6 col-lg-4">
								<div className="el">
									<label>OAuth 2.0 Token End Point *</label>	
									<input className="form-control" type="text"
                                    name="authtokendpoint" value={authtokendpoint}
                                    onChange={(e) => props.onDataInputFieldHnadleChange(e)} 
                                    />
									<div className="errorMsg">{props.errors["authtokendpoint"]}</div>
								</div>
							</div>
							<div className="col-sm-6 col-lg-4">
								<div className="el">
									<label>Client ID *</label>	
									<input className="form-control" type="text"
                                    name="clientid" value={clientid}
                                    onChange={(e) => props.onDataInputFieldHnadleChange(e)}
                                    />
									<div className="errorMsg">{props.errors["clientid"]}</div>
								</div>
							</div>

							<div className="col-sm-6 col-lg-4">
								<div className="el">
									<label>Client Secret *</label>	
									<input className="form-control" type="text"
                                    name="clientsecretkey" value={clientsecretkey}
                                    onChange={(e) => props.onDataInputFieldHnadleChange(e)}
                                    />
									<div className="errorMsg">{props.errors["clientsecretkey"]}</div>
								</div>
							</div>
							<div className="col-sm-6 col-lg-4">
								<div className="el">
									<label>Other Information </label>	
									<input className="form-control" type="text" 
                                    name="otherinformation" value={otherinformation}
                                    onChange={(e) => props.onDataInputFieldHnadleChange(e)}
                                    />
									{/* <div className="errorMsg">{props.errors["otherinformation"]}</div> */}
								</div>
							</div>
                            <div className="col-sm-6 col-lg-4">
								<div className="el">
									<label>Status Update Miutes(Min.) *</label>	
									<input className="form-control" type="text" 
                                    name="updateStatusTime" value={updateStatusTime}
                                    onChange={(e) => props.onDataInputFieldHnadleChange(e)}
                                    />
									<div className="errorMsg">{props.errors["updateStatusTime"]}</div>
								</div>
							</div>

							<div className="col-sm-6 col-lg-4">
								<div className="el">
									<label>Status</label>	
									<select className="form-control"
                                    name="status" value={status}
                                    onChange={(e) => props.onDataInputFieldHnadleChange(e)}
                                    >
										<option value="1" key="Active">Active</option>
										<option value="0" key="Deactive">Deactive</option>
									</select>
									 {/* <div className="errorMsg">{props.errors["agentid"]}</div>  */}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="down_pan">
					<div className="text-right">
						<Link to="/admin/listinternalcrm"><button className="btn_cf dim"><i className="fa fa-times" aria-hidden="true">&nbsp;</i> Cancel</button></Link> &nbsp;
						<button className="btn_cf scss"  onClick={() => props.onAddRealEstate()}>
                            <i className="fal fa-save"></i>&nbsp;{props.internalById.id ? "Update":"Save"} </button>
					</div>
				</div>

    </>
)
                        
      }
export default AddInternal;