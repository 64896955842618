import React from "react";
import { Button } from "react-bootstrap";

export default function NoMatch() {
  return (
    <div className="center-data">
      <div className="no-match">
        <h1>ERROR 404</h1>
        <h2>PAGE NOT FOUND</h2>
        <p>
          The page your are trying to access is either deleted or has been moved
        </p>

        <Button variant="add-data" type="button">
          Go to Login Page
        </Button>
        <img src="/assets/images/error-img.svg" alt="error images" />
      </div>
    </div>
  );
}