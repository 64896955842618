import _ from "lodash";
var CryptoJS = require("crypto-js");



export const getBasicAuthToken = (username, password) => {
  return "Basic " + btoa(username + ":" + password);
}


export const encrypt = (data, encrptionKey) => {
   return CryptoJS.AES.encrypt(data, encrptionKey).toString();
};

export const decrypt = (data, encrptionKey) => {
   return CryptoJS.AES.decrypt(data, encrptionKey).toString();
};

/**
 * returns trim value
 * @param {text} value value to be trim for white space
 */
 export const removeWhiteSpace = (value) => {
   let trimValue = value;
   if (/^\s/g.test(trimValue)) {
     trimValue = trimValue && trimValue.replace(/^\s+/, "");
   }
   if (trimValue) {
     // console.log(trimValue === Date(trimValue));
     trimValue =
       trimValue === Number(trimValue) || trimValue === Boolean(trimValue)
         ? value
         : trimValue && trimValue.replace(/\s\s+/g, " ");
   }
   return trimValue;
 };

 
export const buildSelectOptions = (
  obj,
  value,
  label,
  label2,
  label3 = "defaultNoSortOrder"
) => {
  const objects = _.orderBy(
    obj,
    [(o) => (!isNaN(+o[label]) ? +o[label] : o[label]?.toLowerCase())],
    ["asc"]
  );

  return (
    objects &&
    objects.map((item) => {
      if (label3 !== "defaultNoSortOrder") {
        return {
          key: item[value] + item[label],
          value: item[value],
          label: label2 ? `${item[label]} ${item[label2]}` : item[label],
          [label3]: item[label3],
        };
      } else {
        return {
          key: item[value] + item[label],
          value: item[value],
          label: label2 ? `${item[label]} ${item[label2]}` : item[label],
        };
      }
    })
  );
};
 