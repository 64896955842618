import React from "react";

const Footer = (props) => {
    let reciveDate = new Date();
    return (
        <React.Fragment>
            <footer className="sticky-footer bg-white">
                <div className="my-auto px-4">
                    <div className="copyright d-flex justify-content-between my-auto">
                        <span> Copyright © {reciveDate.getFullYear()} Campaignflow </span>
                    </div>
                </div>
            </footer>
        </React.Fragment>
    )
}
export default Footer;